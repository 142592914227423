import { map } from 'nanostores';
import { atom, action } from 'nanostores';

const initialValue: never[] = [];
const categories = atom(initialValue);
const category = map({});
const panStatus = atom(null);
const setCategoriesInState = action(categories, 'setCategoriesInState', (store: any, payload: any) => {
	categories.set(payload);
	return store.get();
});

const setCategoryInState = action(category, 'setCategoriesInState', (store: any, payload: any) => {
	category.set(payload);
	return store.get();
});

const setPanStatusInState = action(panStatus, 'setPanStatusInState', (store: any, payload: any) => {
	panStatus.set(payload?.getNameByPanCard?.name);
	return store.get();
});
export { categories,category, panStatus, setCategoriesInState, setPanStatusInState, setCategoryInState };