import { executeApi } from '../common';
import { getCategories, getCategory, verifyPanQuery } from '../queries';
import { setCategoriesInState, setPanStatusInState, setCategoryInState } from './store';

const verifyPan = async function verifyPan(pan: string){
	const response = await executeApi(verifyPanQuery, {pan});
	setPanStatusInState(response.data);
	console.log(response,'res')
	return response.data;
 }; 
 const fetchCategories = async function fetchCategories(){
	const {data: response} = await executeApi(getCategories);
	setCategoriesInState(response?.Categories?.data);
	return response;
 };
 const fetchCategory = async function fetchCategory(id: string){
	const {data: response} = await executeApi(getCategory, id);
	console.log(response,'ress')
	setCategoryInState(response?.Category);
	return response;
 };

export { fetchCategories, verifyPan, fetchCategory }