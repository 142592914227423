import { setCountriesInState } from './store';
// import getCounties from '@/graphql/countries.gql';

const fetchCountries = async function fetchCountries(country: any) {
	const response = await fetch("https://countries.trevorblades.com/graphql", {
    method:'POST',
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({
      query: `
        query Countries($filter: CountryFilterInput) {
          countries(filter: $filter) {
            name
            currency
            emoji
            native
            continent {
              name
            }
            states {
              name
            }
            languages {
              name
              native
              rtl
            }
          }
        }
      `,
      variables: {
        "filter": {
          "code": {
            "eq": country || 'IN'
          }
        }
      }
		}),
	});

	const json = await response.json();
	// console.log('JASON', json);
	const x = json.data
	setCountriesInState(x.countries);
	return x.countries;
};

export { fetchCountries };
