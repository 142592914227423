import { atom, action } from 'nanostores';

const initialValue: never[] = [];
const me = atom({});
const user = atom({});
const userbyphone = atom({});
const userbyemail = atom({});
const userfromDynomodb = atom(initialValue);
const currentGroup = atom({})
const setUserDynomodbInState = action(userfromDynomodb, 'setUserDynomodbInState', (store: any, payload: any) => {
	console.log(payload, 'Dynomouser');
	userfromDynomodb.set(payload?.UserByPhone);
	return store.get();
});
const setMeInState = action(me, 'setMeInState', (store: any, payload: any) => {
	me.set(payload || {});
	return store.get();
});
const setCheckByPhone = action(userbyphone, 'setCheckByPhone', (store: any, payload: any) => {
	console.log("payloadpayload", payload);
	userbyphone.set(payload);
	return store.get();
});

const setCheckByEmail = action(userbyemail, 'setCheckByEmail', (store: any, payload: any) => {
	console.log("payloadpayload", payload);
	userbyemail.set(payload);
	return store.get();
});
const setMeDetailsInState = action(user, 'setMeDetailsInState', (store: any, payload: any) => {
	user.set(payload || {});
	const findGroup = payload.userGroup.reduce((a:any, b:any) => a.createdAt > b.createdAt ? a : b).Group;
	if(findGroup){
		console.log(findGroup,'fyGroup')
		currentGroup.set(findGroup.value);
	}
	return store.get();
});

export { userfromDynomodb, me, user,currentGroup, setUserDynomodbInState, setMeInState, setMeDetailsInState, setCheckByPhone, userbyphone, setCheckByEmail, userbyemail };