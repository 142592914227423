
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonicSlides,
  IonSearchbar,
  IonButton,
  IonItem,
  IonList,
  IonLabel,
  IonAvatar,
  IonSpinner,
} from "@ionic/vue";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useStore } from "@nanostores/vue";
import { allCustomersStore, fetchAllCustomers } from "@/store/customers";
import { setCustomerInState } from "@/store/customers/store";
const allCustomers = useStore(allCustomersStore);

export default defineComponent({
  name: "CustomersPage",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    Swiper,
    SwiperSlide,
    IonSearchbar,
    IonAvatar,
    IonLabel,
    IonItem,
    IonList,
    IonSpinner,
  },
  data() {
    return {
      modules: [IonicSlides, FreeMode],
      setCustomerInState,
      allCustomers,
      isLoading: false,
      seachtext:'',
      me: localStorage.getItem("me"),
      steps: {
        startDate: "",
        endDate: "",
        value: 0,
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    await fetchAllCustomers();
    this.isLoading = false;
  },
  methods: {

  },
  computed: {
    filterdata(): any {
      return this.allCustomers?.filter((x: any) => x?.custUser?.firstName?.toLowerCase().includes(this.seachtext.toLowerCase()));
    },
  },
});
