import { executeApi } from '../common';
import { getProducts, getProduct, getcatProducts } from '../queries';
import { setAllProductsIdInState, setProductInState, setAllCatProductsInState } from './store';

const fetchAllCatProducts = async function fetchAllCatProducts(variables: any) {
	const {data: response} = await executeApi(getcatProducts ,variables);
	setAllCatProductsInState(response.Products.data);
	return response;
};

const fetchAllProducts = async function fetchAllProducts(variables: any) {
	const {data: response} = await executeApi(getProducts ,variables);
	setAllProductsIdInState(response.Products.data);
	return response;
};

const fetchProduct = async function fetchProduct(variable: any) {
	const {data: response} = await executeApi(getProduct, variable);
	setProductInState(response.Product);
	return response.Product;
};

export { fetchAllProducts, fetchProduct, fetchAllCatProducts }