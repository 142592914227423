
import { defineComponent } from "vue";
import { onMounted } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonButton,
  // IonSlides,
  // IonSlide,
  // IonSegment,
  // IonSegmentButton,
  // IonicSlides,
  // IonAvatar,
} from "@ionic/vue";
import { useStore } from "@nanostores/vue";
import { ref } from "vue";
import { fetchProduct, selectedProduct } from "@/store/products";
import {
  fetchAllCustomers,
  createApplication,
  allCustomersStore,
} from "@/store/customers";
import { user } from "@/store/users";

const allAssociatedCust = useStore(allCustomersStore);
const storeSelectedProduct = useStore(selectedProduct);
const userData = useStore(user);

export default defineComponent({
  name: "CustomerDetailPage",
  // components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonButtons, IonSlides, IonSlide, IonSegment, IonSegmentButton, Swiper, SwiperSlide, Tabs, Tab, TabPanels, TabPanel },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonBackButton,
    IonButtons,
    IonButton,
  },

  setup() {
    const agreed = ref(false);
    onMounted(async () => {
      await fetchAllCustomers();
    });

    return {
      storeSelectedProduct,
      allAssociatedCust,
      userData,
      agreed,
      loanDetails: {
        tenure: 24,
        min_loan_amount: 50000,
        max_loan_amount: 700000,
      },
    };
  },
  async mounted() {
    if (!this.storeSelectedProduct)
      await fetchProduct({ id: this.$route.params.id });
  },
  data() {
    return {
      selCustForAppl: {
        id: null,
        custUser: {
          id:null,
          panCard: null,
        },
      },
    };
  },
  watch: {
    // fullName(val) {
    //   const names = val.split(" ");
    //   this.userDetails.firstName = names[0];
    //   this.userDetails.lastName =
    //     names.length > 1 ? names[names.length - 1] : "";
    // },
  },
  methods: {
    async createApplicationFunc() {
      console.log("1000", this.loanDetails.min_loan_amount);
      if(this.loanDetails.min_loan_amount > this.loanDetails.max_loan_amount){
        alert("The Desired loan amount cannot exceed the Max loan amount ")
      }
      const variables = {
        ...this.loanDetails,
        statusId: "a6364839-67aa-4b54-a280-2223c3929092",
        customerId: this.selCustForAppl?.custUser?.id,
        addedBy: this.userData?.id,
        productid: this.storeSelectedProduct?.id,
        sourceId: this.storeSelectedProduct?.source,
      };
      console.log("variables", variables);
      createApplication(variables);
      this.$router.push({
        path: `/dashboard/product/${this.$route.params.id}`,
      });
    },
  },
});
