import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import Session from "supertokens-web-js/recipe/session";

const attemptSessionRefresh = async () => {
  if (!await Session.attemptRefreshingSession()) {
    console.log('attempt to refresh the token failed');
    await Session.signOut();
    window.location.assign("/login");
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.attemptSessionRefresh = attemptSessionRefresh;

const routes: Array<RouteRecordRaw> = [
  {
    name: 'RootPage',
    path: '/',
    meta: { auth: false },
    redirect: '/welcome'
  },
  {
    name: 'WelcomePage',
    path: '/welcome',
    meta: { auth: false },
    component: () => import('@/views/WelcomeScreen.vue')
  },
  {
    name: 'LoginPage',
    path: '/login',
    meta: { auth: false },
    component: () => import('@/views/LoginPage.vue')
  },
  {
    name: 'AuthCallbackPage',
    path: '/auth/callback/:provider',
    meta: { auth: false },
    component: () => import('@/views/AuthCallback.vue')
  },
  // {
  //   name: 'KYCPage',
  //   path: '/kyc',
  //   meta: { auth: true },
  //   component: () => import('@/views/KYCPage.vue')
  // },
  {
    name: 'DashboardShellPage',
    path: '/dashboard',
    meta: { auth: true },
    component: TabsPage,
    children: [
      {
        name: 'DashboardDefaultPage',
        path: '',
        meta: { auth: true },
        redirect: '/dashboard/summary'
      },
      {
        path: 'summary',
        meta: { auth: true },
        component: () => import('@/views/TabHomePage.vue')
      },
      {
        path: 'onboarding',
        meta: { auth: true },
        component: () => import('@/views/OnboardingUpdateProfilePage.vue')
      },
      {
        path: 'category/:id',
        meta: { auth: true },
        component: () => import('@/views/CategoryPage.vue')
      },
      {
        path: 'profile/:id',
        meta: { auth: true },
        component: () => import('@/views/UpdateProfilePage.vue')
      },
      {
        path: 'product/:id',
        meta: { auth: true },
        component: () => import('@/views/ProductDetailPage.vue')
      },
      {
        path: 'customers',
        meta: { auth: true },
        component: () => import('@/views/TabCustomersPage.vue')
      },
      {
        path: 'products',
        meta: { auth: true },
        component: () => import('@/views/TabproductsPage.vue')
      },
      
      {
        path: 'customers/:id',
        meta: { auth: true },
        component: () => import('@/views/CustomerDetailPage.vue')
      },
      {
        path: 'customer/new',
        meta: { auth: true },
        component: () => import('@/views/NewCustomerPage.vue')
      },
      {
        path: 'application/:id',
        meta: { auth: true },
        component: () => import('@/views/ApplicationDetailPage.vue')
      },
      {
        path: 'applications',
        meta: { auth: true },
        component: () => import('@/views/TabApplicationsPage.vue')
      },
      {
        path: 'createapplication/:id',
        meta: { auth: true },
        component: () => import('@/views/NewApplicationPage.vue')
      },
      {
        path: 'Settings',
        meta: { auth: true },
        component: () => import('@/views/Settings.vue')
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/dashboard/summary'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('accessToken');
  // const isAuthenticated = false;
  if ('auth' in to.meta && to.meta.auth && !isAuthenticated) {
    console.log('redirect to login');
    console.log(to.path);
    // if (from.path.includes('/welcome') && to.path.includes('/login')) next();
    // else next('/login');
    next('/login');
  } else if ('auth' in to.meta && !to.meta.auth && isAuthenticated) {
    console.log('else if');
    if (!(window.location.pathname.includes('/login') || window.location.pathname.includes('/auth'))) {
      try {
        if (!await Session.doesSessionExist()) {
          console.log('session does not exists');
          await attemptSessionRefresh();
        } else console.log('session available');
      } catch (error) {
        console.log(error);
      }
    }
    try {
      console.log(to.path);
      if (!to.path.includes('dashboard')) next('/dashboard');
      else next(to.path);
    } catch (error) {
      next('/dashboard');
    }
  } else {
    next();
  }
});

export default router
