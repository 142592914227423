<template>
  <section>
    <h1
      v-if="heading"
      class="mt-10 mb-6 mx-5 text-lg leading-none"
      v-html="heading"
    />
    <swiper
      id="swiperAll"
      v-if="banners?.length"
      :modules="modules"
      slides-per-view="auto"
      :free-mode="true"
      :space-between="16"
      :autoplay="{
        delay: 2500,
      }"
      :keyboard="true"
      :scrollbar="true"
      :zoom="true"
      :options="options"
    >
      <swiper-slide v-for="(banner, index) in banners" :key="index">
        <div class="w-80 shadow-lg" :class="banner.class">
          <a href="https://royalfinserv.co.in/" target="_blank">
            <img :src="banner.imgUrl" alt="" class="w-full rounded-lg" />
          </a>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

export default {
  name: "AutoplaySlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    banners: {
      type: Array,
      required: true,
    },
    heading: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    return {
      props,
      modules: [Autoplay],
      options: {},
      slideOptsOne: {
        initialSlide: 0,
        slidesPerView: 1,
        autoplay: { delay: 2500 },
      },
    };
  },
};
</script>
