
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  // IonBackButton,
  IonButtons,
  // IonSlides,
  // IonSlide,
  // IonSegment,
  // IonSegmentButton,
  // IonicSlides,
  // IonAvatar,
} from "@ionic/vue";
import BtnSpinner from "@/components/spinner.vue";
import { useStore } from "@nanostores/vue";
import { Field, Form } from "vee-validate";
import { formatDDMMYYYY, formatYYYYMMDD } from "../services/date-format";
// import BpUpload from "@/components/BpUpload.vue";
import {
  me,
  fetchMe,
  userbyphone,
  user,
  updateMyDetails,
  updateMyDetailsonboard,
  CheckByEmail,
  userbyemail,
} from "@/store/users";
import { customerpanKyc,customeraadharKyc, panKycStore, aadharKycStore } from "@/store/customers";
const userstore = useStore(user);
const meData = useStore(me);
const storeuserbyphone = useStore(userbyphone);
const storeuserbyemail = useStore(userbyemail);
const storepan = useStore(panKycStore);
const storeaddhar = useStore(aadharKycStore);

export default defineComponent({
  name: "CustomerDetailPage",
  // components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonButtons, IonSlides, IonSlide, IonSegment, IonSegmentButton, Swiper, SwiperSlide, Tabs, Tab, TabPanels, TabPanel },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    // IonBackButton,
    IonButtons,
    BtnSpinner,
    Field,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    // BpUpload,
    // IonSlides,
    // IonSlide,
    // IonSegment,
    // IonSegmentButton,
    // IonAvatar,
  },

  setup() {
    return {
      meData,
      storeuserbyphone,
      storeuserbyemail,
      userstore,
      storepan,
      storeaddhar
    };
  },

  data() {
    return {
      dateofbirth: "",
      userGroup: {},
      userProject: {
      },
      formatDDMMYYYY,
      formatYYYYMMDD,
      step: 3,
      // fullName: "",
      userDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        dialCode: "+91",
        pincode: "",
        dob: "",
        isOnBoard: true,
        panCard: "",
        gender:"",
        aadharCard:"",
        id: "",
        usergroupid: "",
        usermetaid: "",
        addressLine1:'',
        addressid:'',
        userprojectid:'',
        csm: localStorage.getItem("me"),
      },
      // agreed: false,
      otp: null,
      verifying: false,
      isLoading: false,
      isverified: false,
      isverifiedpan: false,
      isverifiedaddhar: false,
      buttonchange: false,
      buttonchangeaddhar: false,
      buttonchangepan: false,
    };
  },
  async mounted() {
    await fetchMe();
    this.userDetails.phoneNo = this.userstore?.phoneNo;
      this.userDetails.addressLine1 = this.userstore?.userProject[0]?.Address[0]?.addressLine1;
      this.userDetails.addressid = this.userstore.userProject[0]?.Address[0]?.id;
      this.userDetails.userprojectid= this.userstore?.userProject[0]?.id;
      this.userDetails.firstName = this.userstore?.firstName;
      this.userDetails.lastName = this.userstore?.lastName;
      this.dateofbirth = this.userstore?.dob.split("-").reverse().join("-");
      this.userDetails.email = this.userstore?.email;
      this.userDetails.panCard = this.userstore?.panCard;
      this.userDetails.gender = this.userstore?.gender;
      this.userDetails.aadharCard = this.userstore?.aadharCard;
      this.userstore?.userMeta?.forEach((element: any) => {
        if (element?.metaKeyProject === "801c8b62-d7ea-4089-b569-804dbfec2f83") {
          this.userDetails.pincode = element?.value;
          this.userDetails.usermetaid = element?.id;
        }
      });
  },
  watch: {
    // fullName(val) {
    //   const names = val.split(" ");
    //   this.userDetails.firstName = names[0];
    //   this.userDetails.lastName =
    //     names.length > 1 ? names[names.length - 1] : "";
    // },
  },
  methods: {
    isRequired(value: any) {
      return value ? true : "This field is required";
    },
    async verifypankyc(value: any) {
      await customerpanKyc({ card: value });
      this.isverifiedaddhar = true;
      console.log("this.storepan", this.storepan);
      if(this.storepan === 'error'){
        this.buttonchangepan = false;
        this.isverifiedaddhar = true;
        alert("Not able to verify the Pan No. Please try again!!!");
      }else{
        this.buttonchangepan = true;
        // this.isverifiedaddhar = false;
        this.isverified = false;
      }
    },
   async verifyaadharkyc(value: any) {
      await customeraadharKyc({ card: value });
      // this.isverified = true;
      if(this.storeaddhar === 'error'){
        this.buttonchangeaddhar = false;
        // this.isverified = true;
        alert("Not able to verify the Aadhar No. Please try again!!!");
      }else{
        this.buttonchangeaddhar = true;
        this.isverified = false;
      }
    },
    verifyMobile() {
      this.verifying = true;
      setTimeout(() => {
        this.verifying = false;
        this.step += 1;
      }, 1200);
    },
    async onSubmit() {
      console.log(this.userDetails, "uu");
      this.userDetails.dob = formatDDMMYYYY(this.dateofbirth);
      this.userDetails.id = this.userstore.id;
      await CheckByEmail({ emailId: this.userDetails.email });
      this.isLoading = true;
      await updateMyDetailsonboard(this.userDetails);
      this.$router.push({ path: "/dashboard/summary" });
      this.isLoading = false;
    },
  },
});
