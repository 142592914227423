import { atom, action } from 'nanostores';

const initialValue: never[] = [];
const allCustomersStore = atom(initialValue);
const customerKycStore = atom(initialValue);
const selectedCustomer = atom(null)
const productCustomersStore = atom(initialValue);
const allapplications = atom(initialValue);
const application = atom(initialValue);
const groups = atom(initialValue);
const panKycStore = atom(initialValue);
const aadharKycStore = atom(initialValue);

const setAllCustomersInState = action(allCustomersStore, 'setAllCustomersInState', (store: any, payload: any) => {
	allCustomersStore.set(payload.length ? payload : []);
	return store.get();
});

const setpanKycInState = action(panKycStore, 'setpanKycInState', (store: any, payload: any) => {
	panKycStore.set(payload);
	return store.get();
});

const setaadharKycInState = action(aadharKycStore, 'setaadharKycInState', (store: any, payload: any) => {
	aadharKycStore.set(payload);
	return store.get();
});
const setCustomerInState = action(allCustomersStore, 'setCustomerInState', (store: any, payload: any) => {
	selectedCustomer.set(payload);
	return store.get();
});
const setProductCustomerstInState = action(productCustomersStore, 'setProductCustomerstInState', (store: any, payload: any) => {
	productCustomersStore.set(payload);
	return store.get();
});

const setAllApplicationsInState = action(allapplications, 'setAllApplicationsInState', (store: any, payload: any) => {
	allapplications.set(payload.length ? payload : []);
	return store.get();
});

const setApplicationInState = action(application, 'setApplicationInState', (store: any, payload: any) => {
	application.set(payload ? payload : []);
	return store.get();
});

const setGroupsInState = action(groups, 'setProductCustomerstInState', (store: any, payload: any) => {
	groups.set(payload.length ? payload : []);
	return store.get();
});

export { groups, selectedCustomer, allCustomersStore, productCustomersStore, setAllCustomersInState, setProductCustomerstInState, setCustomerInState, setGroupsInState, setAllApplicationsInState, allapplications ,setApplicationInState, setpanKycInState,panKycStore, setaadharKycInState, aadharKycStore, application };
