<template>
  <ion-content fullscreen class="ion-padding" :scroll-y="false">
    <main class="flex flex-col h-full justify-between pb-5 px-3">
      <div class="flex justify-start top-0 w-full bg-white py-1">
        <!-- <img
          src="https://ucarecdn.com/108b9469-15c3-4841-af24-99c617d7b11d/royal_finservLOGO1.png"
          alt="logo"
        /> -->
      </div>
      <img
        src="../assets/images/royal_finserv.png"
        class="px-10 w-10/12 mx-auto"
        alt="logo"
      />
      <!-- <div class="flex flex-col mt-12 space-y-5">
        <button @click.prevent="signInWithGoogle" class="flex justify-center items-center h-12 px-6 border border-blue-100 rounded-lg bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-200 shadow">
          <div class="flex items-center justify-center space-x-2">
            <img src="../assets/images/google-icon.svg" class="w-5" alt="" />
            <span class="block text-sm font-medium tracking-wide text-blue-700 w-max">Sign in with Google</span>
          </div>
          <loader v-if="isLoading" width="w-4" class="mx-2" />
        </button>

        <button @click.prevent="onApplePressed" class="h-12 px-6 transition bg-gray-900 rounded-lg hover:bg-gray-800 active:bg-gray-600 focus:bg-gray-700 shadow">
          <div class="flex items-center justify-center space-x-2 text-white">
            <svg width="20px" viewBox="0 0 256 315" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" class="mr-1">
                <g>
                    <path d="M213.803394,167.030943 C214.2452,214.609646 255.542482,230.442639 256,230.644727 C255.650812,231.761357 249.401383,253.208293 234.24263,275.361446 C221.138555,294.513969 207.538253,313.596333 186.113759,313.991545 C165.062051,314.379442 158.292752,301.507828 134.22469,301.507828 C110.163898,301.507828 102.642899,313.596301 82.7151126,314.379442 C62.0350407,315.16201 46.2873831,293.668525 33.0744079,274.586162 C6.07529317,235.552544 -14.5576169,164.286328 13.147166,116.18047 C26.9103111,92.2909053 51.5060917,77.1630356 78.2026125,76.7751096 C98.5099145,76.3877456 117.677594,90.4371851 130.091705,90.4371851 C142.497945,90.4371851 165.790755,73.5415029 190.277627,76.0228474 C200.528668,76.4495055 229.303509,80.1636878 247.780625,107.209389 C246.291825,108.132333 213.44635,127.253405 213.803394,167.030988 M174.239142,50.1987033 C185.218331,36.9088319 192.607958,18.4081019 190.591988,0 C174.766312,0.636050225 155.629514,10.5457909 144.278109,23.8283506 C134.10507,35.5906758 125.195775,54.4170275 127.599657,72.4607932 C145.239231,73.8255433 163.259413,63.4970262 174.239142,50.1987249" fill="#ffffff"></path>
                </g>
            </svg>
            <span class="block text-sm font-medium tracking-wide text-white w-max">Sign in with Apple</span>
          </div>
        </button>

        <button @click.prevent="onGithubPressed" class="h-12 px-6 transition bg-gray-900 rounded-lg hover:bg-gray-800 active:bg-gray-600 focus:bg-gray-700">
          <div class="flex items-center justify-center space-x-2 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-5" viewBox="0 0 16 16">
              <path
                d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"
              />
            </svg>
            <span class="block text-sm font-medium tracking-wide text-white w-max">Sign in with Github</span>
          </div>
        </button>
      </div> -->

      <!-- <div class="mt-12 border-t">
      <span class="block px-4 mx-auto -mt-3 text-center text-gray-500 bg-white w-max">OR</span>
    </div> -->

      <form
        v-on:submit="onSubmitPressed"
        autocomplete="on"
        novalidate
        class="mt-3 space-y-6"
      >
        <h3 class="mt-10 mb-2 text-xl">
          <div v-show="otpRequested">
            Enter the OTP sent to
            <button
              type="button"
              class="flex items-center mt-1"
              @click.prevent="goBackToSignIn"
            >
              <span class="mr-1 text-brand-primary font-bold">{{
                fullNumber
              }}</span>
              <span class="text-sm italic font-bold leading-none text-gray-700">
                (Edit)
              </span>
            </button>
          </div>
          <div v-show="!otpRequested">
            Lets get you started on
            <p class="flex items-center mt-1">
              <span class="mr-1 text-brand-primary font-bold"
                >Royal Finserv</span
              >
            </p>
          </div>
        </h3>

        <VueTelInput
          v-show="!otpRequested"
          :class="dirty && !isMobileValid ? 'has-error' : ''"
          v-model="phone"
          @validate="validate"
          @input="phoneChanged"
          :default-country="'in'"
          :dropdown-options="{
            showFlags: true,
            showDialCodeInSelection: true,
            validCharactersOnly: true,
            disabled: true,
          }"
          :input-options="{
            placeholder: 'Enter your mobile number',
            required: true,
            disabled: otpRequested,
            readonly: otpRequested,
          }"
          :auto-format="false"
        >
          <template #arrow-icon>
            <span class="vti__dropdown-arrow text-lg">▼</span>
          </template>
        </VueTelInput>
        <div class="text-xs text-gray-600 flex items-start">
          <span class="mr-2 w-9">
            <input
              type="checkbox"
              class="outline-none focus:outline-none"
              v-model="agreed"
            />
          </span>
          <span class="w-auto">
            hereby authorize Royal Finserv and its Affiliates to retrieve my
            Credit Information Report. Also, Authorize to send notifications
            through our communication channels.</span
          >
        </div>
        <div
          v-show="otpRequested"
          ref="otpInput"
          class="relative vue-tel-input"
          :class="{ 'has-error': !isOtpValid }"
        >
          <div class="flex justify-between items-center w-full">
            <!-- <label class="block mb-3 text-gray-800 capitalize" for="otp">
            OTP
          </label> -->
            <input
              id="otp"
              ref="otp-input"
              @input="validateOtp"
              class="block w-full py-2.5 px-4 text-gray-600 rounded-md appearance-none focus:bg-white focus:border-gray-500 focus:outline-none"
              type="tel"
              placeholder="******"
              :disabled="loading"
            />
            <button
              v-show="otpRequested"
              class="font-semibold text-xs"
              type="button"
              @click.prevent="resetOtpRequest"
              :disabled="timer"
              :class="[
                timer ? 'text-gray-400' : 'rounded h-full text-blue-700',
              ]"
            >
              {{ timer ? `Request OTP again in ${timer}` : "Resend OTP" }}
            </button>
          </div>

          <!-- <p class="text-xs italic text-red">Please choose a otp.</p> -->
        </div>

        <!-- <input class="placeholder:italic placeholder:text-gray-400 block bg-white w-full border border-gray-400 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 sm:text-sm" placeholder="Search for anything..." type="text" name="search"/> -->

        <!-- <pre>{{ phone || 'na' }}</pre>
      <pre>{{ isMobileValid }}</pre>
      <pre>{{ otp }}</pre>
      <pre>{{ isOtpValid }}</pre> -->

        <button
          class="w-full px-6 py-3 transition bg-blue-500 flex justify-center rounded-lg hover:bg-blue-600 focus:bg-blue-600 active:bg-blue-800"
          :class="[
            (isMobileValid && !otpRequested && agreed) ||
            (otpRequested && isOtpValid && agreed)
              ? ''
              : 'opacity-75',
          ]"
          :disabled="
            (isMobileValid && !otpRequested && !agreed) ||
            (otpRequested && !isOtpValid)
          "
        >
          <span v-if="!loading" class="text-lg font-semibold text-white">{{
            otpRequested ? "Confirm" : "Proceed"
          }}</span>
          <BtnSpinner v-else :loading="true" class="text-center my-1" />
        </button>
      </form>
    </main>
  </ion-content>
</template>

<!-- <script lang="ts"> -->
<script>
import ThirdPartyPasswordless from "supertokens-web-js/recipe/thirdpartypasswordless";
import { IonContent, loadingController } from "@ionic/vue";
import { VueTelInput } from "vue-tel-input";
import * as jose from "jose";
import { ref } from "vue";
import { Capacitor } from "@capacitor/core";
import BtnSpinner from "@/components/spinner.vue";
// import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import "vue-tel-input/dist/vue-tel-input.css";

const supertokensLoginFunc = (authUrl) => {
  const options =
    "location=no,navigationbuttoncolor=#ffffff,closebuttoncaption=Close,closebuttoncolor=#ffffff,enableViewportScale=yes";
  // if (Capacitor.getPlatform() === 'android') {
  //   options = 'hideurlbar=yes,location=yes,hardwareback=no,hidenavigationbuttons=true';
  // }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.cordova) {
    // console.log(`${websiteDomain}/auth?provider=google&hostId=${Capacitor.isNative ? 'tech.butterpaper.royalfinserve' : window.location.host}&hostPlatform=${Capacitor.isNative ? Capacitor.getPlatform() : 'web'}`);
    const providerUrl = `${websiteDomain}/auth?hostId=${
      Capacitor.isNative
        ? "tech.butterpaper.royalfinserve"
        : window.location.host
    }&hostPlatform=${
      Capacitor.isNative ? Capacitor.getPlatform() : "web"
    }&providerUrl=${encodeURIComponent(authUrl)}`;
    console.log(encodeURIComponent(authUrl));
    console.log(providerUrl);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.inAppBrowserRef = window.cordova.InAppBrowser.open(
      providerUrl,
      "_blank",
      options
    );
    window.inAppBrowserRef.addEventListener("exit", () => {
      console.log("destroying inAppBrowserRef");
      window.inAppBrowserRef.close();
    });

    window.inAppBrowserRef.addEventListener("loaderror", () => {
      console.log("destroying inAppBrowserRef");
      window.inAppBrowserRef.close();
    });

    window.inAppBrowserRef.addEventListener("message", async (message) => {
      const { data } = message;
      // console.log(data.type, data.name, data.payload.cookie);
      if (
        data &&
        data.type === "event" &&
        data.name &&
        data.name === "AuthLoginCallback"
      ) {
        const frontTokenFromRequestHeader = window.getCookie(
          "st-access-token",
          data.payload.cookie
        );
        if (frontTokenFromRequestHeader) {
          // const { up } = JSON.parse(decodeURIComponent(escape(atob(frontTokenFromRequestHeader))));
          // const { jwt } = up;
          // if (jwt) {
          //   const decodedToken = jose.decodeJwt(jwt);
          //   const { exp: expiryTime } = decodedToken;
          //   // console.log(decodedToken);
          //   // console.log('===============');
          //   // return frontTokenFromRequestHeader && expiryTime! * 1000 > Date.now() && issuer === 'https://auth.butterpaper.tech/api/auth';
          //   // return frontTokenFromRequestHeader && issuer === 'https://auth.butterpaper.tech/api/auth';
          //   localStorage.setItem('accessTokenExpiry', (expiryTime || '').toString());
          //   localStorage.setItem('accessToken', jwt);
          //   console.log('Message from in app website about successful login');
          // }
        } else {
          localStorage.clear();
        }
        // window.open(data.url, '_system');
        // try {
        //   const { accessToken } = data.payload.query;
        //   console.log(accessToken);
        //   if (accessToken) {
        //     this.$http.defaults.headers.common.Authorization = `Bearer ${accessToken}`; //eslint-disable-line
        //     const user = await this.fetchUser();
        //     this.setSession({
        //       user,
        //       accessToken,
        //     });
        //     await this.getSessionFromLocalDb;
        //   }
        //   inAppBrowserRef.close();
        //   window.location.href = '/';
        // } catch (e) {
        //   console.log(e);
        // }
        window.inAppBrowserRef.close();
        window.location.assign("/dashboard/summary");
      }
    });
  } else {
    window.location.assign(authUrl);
  }
};

// import { Browser } from '@capacitor/browser';
// import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let phoneValid = null;
// eslint-disable-next-line
let interval = null;

// const websitePort = 3000;
// const websiteDomain = 'https://auth.butterpaper.tech';
const websiteDomain =
  (process.env.VUE_APP_BUILD !== "local" &&
    process.env.VUE_APP_RUN_ENV === "web") ||
  !!window.cordova
    ? "https://auth.butterpaper.tech"
    : window.location.origin;

export default {
  components: {
    IonContent,
    BtnSpinner,
    VueTelInput,
  },
  setup() {
    const otpInput = ref(null);

    const handleOnComplete = (value) => {
      console.log("OTP completed: ", value);
    };

    const handleOnChange = (value) => {
      console.log("OTP changed: ", value);
    };

    const clearInput = () => {
      otpInput.value.clearInput();
    };

    const phoneNumber = ref();
    const results = ref();
    const agreed = ref(false);

    return {
      phoneNumber,
      results,
      handleOnComplete,
      handleOnChange,
      clearInput,
      otpInput,
      agreed,
    };
  },
  data() {
    return {
      // we allow the user to switch between sign in and sign up view
      isSignIn: true,

      // this will store the email and password entered by the user.
      email: "",
      password: "",

      phone: "",
      otpRequested: false,

      // any generic error states
      error: false,
      errorMessage: "Something went wrong",

      // any error states specific to the input fields.
      isOtpValid: false,
      isMobileValid: false,
      loading: false,
      timer: 30,
      otp: "",
      fullNumber: "",
      dirty: false,
    };
  },
  async mounted() {
    const params = new URLSearchParams(window.location.search);

    await this.goBackToSignIn();

    if (params.has("error")) {
      this.errorMessage = "Something went wrong";
      this.error = true;
    }
  },
  methods: {
    validateOtp(e) {
      this.isOtpValid = false;
      this.otp = e.target.value;
      if (this.otp.length === 6) this.isOtpValid = true;
    },
    phoneChanged() {
      this.dirty = true;
    },
    validate({ valid: isValid, nationalNumber, number }) {
      if (isValid) {
        // console.log(countryCallingCode, nationalNumber, number);
        // this.mobile = number.significant.replace(/ /g, '');
        this.mobile = nationalNumber.replace(/ /g, "");
        this.isMobileValid = true;
        phoneValid = true;
        this.fullNumber = number;
      } else {
        if (nationalNumber) phoneValid = false;
        this.isMobileValid = false;
      }
    },
    validateEmail(email) {
      return email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async resetOtpRequest() {
      const res = await ThirdPartyPasswordless.resendPasswordlessCode();
      if (res.status === "OK") {
        this.timer = 30;
        // this.otpReRequested = false;
        interval = setInterval(() => {
          this.timer -= 1;
          if (this.timer === 0) this.clearTimers();
        }, 1000);
      } else {
        alert("Error in resending the OTP. Please try again");
        this.goBackToSignIn();
      }
    },
    async goBackToSignIn() {
      this.clearTimers();
      this.otp = "";
      this.otpRequested = false;
      await ThirdPartyPasswordless.clearPasswordlessLoginAttemptInfo();
    },
    clearTimers() {
      try {
        clearInterval(interval);
      } catch (e) {
        console.warn(e);
      }
    },
    async onSubmitPressed(e) {
      e.preventDefault();
      console.log("pressed");
      // this.otpRequested = true;
      // this.timer = 30;
      // // this.otpReRequested = false;
      // interval = setInterval(() => {
      //   this.timer -= 1;
      //   if (this.timer === 0) this.clearTimers();
      // }, 1000);
      // setTimeout(() => {
      //   this.$refs['otp-input'].focus();
      // }, 600);
      // return this.otpRequested = true;

      const options = {
        preAPIHook: async (context) => {
          const url = context.url;

          // // is the fetch config object that contains the header, body etc..
          const requestInit = context.requestInit;
          requestInit.headers = {
            ...requestInit.headers,
            "x-client-id": "gZySd0BUB6uRr1N",
            "x-host-platform": "MOBILE",
            "x-passwordless-type": "PHONE",
            "x-passwordless-contact": this.fullNumber,
          };

          return {
            requestInit,
            url,
          };
          // return context;
        },
      };

      this.loading = true;
      if (!this.otpRequested) {
        try {
          const res = await ThirdPartyPasswordless.createPasswordlessCode({
            phoneNumber: this.fullNumber,
            options,
          });
          this.otpRequested = true;
          this.timer = 30;
          // this.otpReRequested = false;
          interval = setInterval(() => {
            this.timer -= 1;
            if (this.timer === 0) this.clearTimers();
          }, 1000);
          setTimeout(() => {
            this.$refs["otp-input"].focus();
          }, 600);
          this.loading = false;

          if (res.status === "OK") {
            this.otpRequested = true;
          }
        } catch (e) {
          this.loading = false;
          throw e;
        }
      } else {
        try {
          const res = await ThirdPartyPasswordless.consumePasswordlessCode({
            userInputCode: this.otp,
            options,
          });

          this.loading = false;

          if (res.status === "OK") {
            if (res.fetchResponse.headers.get("st-access-token")) {
              const frontTokenFromRequestHeader =
                res.fetchResponse.headers.get("st-access-token");
              if (frontTokenFromRequestHeader) {
                const { userData } = jose.decodeJwt(
                  frontTokenFromRequestHeader
                );
                const { jwt } = userData;
                if (jwt) {
                  const decodedToken = jose.decodeJwt(jwt);
                  const { exp: expiryTime } = decodedToken;
                  localStorage.setItem(
                    "accessTokenExpiry",
                    (expiryTime || "").toString()
                  );
                  localStorage.setItem("accessToken", jwt);
                  console.log(
                    "Message from in app website about successful login"
                  );
                }
              }
              this.$router.replace("/dashboard");
            }
          } else if (res.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
            alert("incorrect OTP");
          } else if (res.status === "RESTART_FLOW_ERROR") {
            alert("Restart flow");
            window.location.href = "/login";
          }
        } catch (error) {
          this.loading = false;
          throw e;
        }
      }
    },
    async signInWithGoogle() {
      const loading = await loadingController.create();
      await loading.present();
      console.log("sign in with google");
      const authUrl =
        await ThirdPartyPasswordless.getThirdPartyAuthorisationURLWithQueryParamsAndSetState(
          {
            providerId: "google",

            // This is where google should redirect the user back after login or error.
            // This URL goes on the google dashboard as well.
            authorisationURL: `${websiteDomain}/auth/callback/google`,
          }
        );
      await loading.dismiss();

      supertokensLoginFunc(authUrl);
    },
    async onApplePressed() {
      const authUrl =
        await ThirdPartyPasswordless.getThirdPartyAuthorisationURLWithQueryParamsAndSetState(
          {
            providerId: "apple",

            // This is where apple should redirect the user back after login or error.
            // This URL goes on the apple dashboard as well.
            authorisationURL: `${websiteDomain}/auth/callback/apple`,
          }
        );

      supertokensLoginFunc(authUrl);
    },
  },
};
</script>

<style>
ion-slides {
  height: 100%;
}
.swiper-slide {
  display: block;
}
.swiper-pagination {
  position: absolute;
  top: 48px;
  padding-bottom: 3px;
  z-index: 0;
}

.vue-tel-input {
  border-width: 1.5px !important;
  --border-opacity: 1 !important;
  border-color: #cbd5e0 !important;
  border-color: rgba(203, 213, 224, var(--border-opacity)) !important;
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
  letter-spacing: 0.01rem;
}

.vue-tel-input,
.vue-tel-input input {
  width: 100% !important;
  --text-opacity: 1 !important;
  color: #718096 !important;
  color: rgba(113, 128, 150, var(--text-opacity)) !important;
  --bg-opacity: 1 !important;
  /* background-color: #edf2f7 !important; */
  /* background-color: rgba(237, 242, 247, var(--bg-opacity)) !important; */
  border-radius: 0.375rem !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.vue-tel-input .vti__dropdown {
  border-right: 1.5px solid #cbd5e0 !important;
  background: transparent !important;
}
/* 
.vue-tel-input.invalid-input,
.vue-tel-input.invalid-input .vti__dropdown {
  border-color: #dc2626 !important;
} */

.vue-tel-input .vti__input {
  padding-left: 0.8rem;
  /* letter-spacing: 1px; */
  color: #222 !important;
}

.vue-tel-input input:active,
.vue-tel-input input:focus,
.vue-tel-input input[focus-within] {
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  --border-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  box-shadow: none !important;
}

.vue-tel-input input:active,
.vue-tel-input input:focus,
.vue-tel-input input:focus-within {
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  --border-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  box-shadow: none !important;
}

.vue-tel-input:active,
.vue-tel-input:focus,
.vue-tel-input[focus-within] {
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  --border-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  box-shadow: none !important;
}

.vue-tel-input:active,
.vue-tel-input:focus,
.vue-tel-input:focus-within {
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
  --border-opacity: 1 !important;
  border-color: #a0aec0 !important;
  border-color: rgba(160, 174, 192, var(--border-opacity)) !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  box-shadow: none !important;
}

.vue-tel-input.has-error {
  --border-opacity: 1 !important;
  border-color: #f56565 !important;
  border-color: rgba(245, 101, 101, var(--border-opacity)) !important;
}

.vue-tel-input.has-error,
.vue-tel-input.has-error:active,
.vue-tel-input.has-error:focus,
.vue-tel-input.has-error[focus-within],
.vue-tel-input.has-error input {
  --bg-opacity: 1 !important;
  background-color: #fff5f5 !important;
  background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
}

.vue-tel-input.has-error:active,
.vue-tel-input.has-error:focus,
.vue-tel-input.has-error:focus-within {
  --bg-opacity: 1 !important;
  background-color: #fff5f5 !important;
  background-color: rgba(255, 245, 245, var(--bg-opacity)) !important;
}
</style>
