export const getUserCustomers = `{
  res: userCustomers {
    userId
    customerId
    user {
      id
      sk
      email
      firstName
      gender
    }
  }
}`;

export const getcatProducts = `query Products($skip:Int, $take:Int , $categoryId: String  ) {
  Products(orderBy: {order: asc}, skip: $skip, take:$take , where: { active:{equals:true}, categoryProduct: {some: {categoryId: {equals: $categoryId}}}}){
    data{
      id
    name
    description
    quantity
    order
    categoryProduct {
      id
      Category {
        id
        name
      }
    }
    productAsset{
      id
      Asset{
        url
      }
    }
    }
  }
}`;

export const getProducts = `query Products($skip:Int, $take:Int ) {
  Products(orderBy: {order: asc}, skip: $skip, take:$take, where:{active:{equals:true}}){
    data{
      id
    name
    description
    quantity
    order
    categoryProduct {
      id
      Category {
        id
        name
      }
    }
    productAsset{
      id
      Asset{
        url
      }
    }
    }
  }
}`;
export const getProduct = `query Product($id:String!) {
  Product(id: $id) {
    id
    name
    description
    quantity
    source
    categoryProduct {
      id
      Category {
        id
        name
        parentCategoryId
      }
    }
    productAsset{
      id
      Asset{
        url
      }
    }
    Application {
      Customer {
        id
        firstName
        lastName
      }
    }
  }
}`;

export const getCategories = `{
  Categories(where: {
    parentCategoryId: {
      equals: null
    }
  }, orderBy: {order: asc}) {
    data{
      id
      name
      order
      childCategories {
        id
        name
        properties
        categoryAsset {
          id
          asset: Asset {
            id
            url
          }
        }
        categoryProduct{
          id
          Product{
            id
            name
            source
          }
        }
      }
      order
      parentCategoryId
      projectId
      properties
      updatedBy
      updatedAt
      visibleAtHome
      description
      deletedAt
      createdBy
      createdAt
      companyId
      code
      active
    }
  }
}`;

export const getCategory = `query Category($id: String!){
  Category(id: $id) {
    id
    name
    parentCategoryId
    childCategories {
      id
      name
      properties
      parentCategoryId
      categoryAsset {
        id
        asset: Asset {
          id
          url
        }
      }
      categoryProduct{
        id
        Product{
          id
          name
          order
          source
        }
      }
    }
    categoryProduct{
      id
      Product{
        id
        name
        description
        quantity
        source
        order
        productAsset{
          id
          Asset{
            url
          }
        }
      }
    }
    order
    parentCategoryId
    projectId
    properties
    updatedBy
    updatedAt
    visibleAtHome
    description
    deletedAt
    createdBy
    createdAt
    companyId
    code
    active
  }
}`;
export const getGroups = `{
  Groups(where: {
    projectId: {
      equals: "159dae41-18f7-4e49-912d-0de0274215f8"
    }
  }) {
    id
    name
    type
  }
}`;
export const updateGroup = `mutation AssignGroup($userId: String!, $groupId: String!){
  AssignGroup(args:{
    userId:$userId
    companyId: "90039c25-0b36-4702-bc65-c9f8bfad059e"
    projectId: "159dae41-18f7-4e49-912d-0de0274215f8"
    groupId:$groupId
}){
  id
}
}`;
export const verifyPanQuery = `query GetNameByPan($pan: String!) {
  getNameByPanCard(pan: $pan) {
    name
  }
}`;

export const getUserDynomodb = `query UserByPhone( $phoneNo: String! ){
  UserByPhone( phoneNo: $phoneNo ){
    firebaseUid
    firstName
    lastName
    phone
    email
  }
}`;

export const addUserDynomodb = `mutation addUser($firstName: String!, $lastName: String!, $email: String, $phoneNo: String, $firebaseUid: String) {
  addUser(
    createBpUserInput: { firstName: $firstName, lastName: $lastName, email: $email, phone: $phoneNo, firebaseUid: $firebaseUid }
  ) {
    dialCode
    email
    firebaseUid
    firstName
    lastName
    password
    phone
    id
    provider
    sk
  }
}`;

export const addUserSQL = `mutation CreateUsers(
  $userId: String
  $companyId: String
  $projectId: String
  $name: String!
  $district: String!
  $address: String!
  $phoneNo: Decimal!
  $pincode: String!
  $firstName: String!
  $lastName: String!
  $userId1: String!
) {
  CreateUsers(
    data: {
      Company: { connect: { id: $companyId } }
      name: $name
      id:$userId
      userCompany: {
        create: { Company: { connect: { id: $companyId } }, active: true }
      }
      userProject: { create: { Project: { connect: { id: $projectId } } } }
      custUser: {
        create: { isVerified: false, User: { connect: { id: $userId1 } } }
      }
      Address: {
        create: {
          City: { create: { name: $district, latitude: "1", longitude: "1" } }
          Country: { create: { name: "India", phoneCode: "91" } }
          Region: {}
          addressLine1: $address
          addressType: "Home"
          isprimary: true
          isverified: true
          phoneNumber: $phoneNo
          zipcode: $pincode
          addressLine2: ""
          latitude: "1"
          longitude: "1"
          firstName: $firstName
          lastName: $lastName
        }
      }
    }
  ) {
    name
    id
    updatedAt
    companyId
    createdAt
    Address {
      addressLine1
    }
  }
}`;

export const getMeQuery = `query me {
  me{
    id
    firstName
    lastName
    phoneNo
    isOnBoard
  }
}`;

// custAddress: $custAddress
// custCity: $custCity
// custCompanyName: $custCompanyName

export const addUserMutation = `mutation createCustomerUser(
  $firstName: String
  $lastName: String
  $email: String
  $phoneNo: String
  $dialCode: String
  $panCard: String
  $gender: genderType
  $aadharCard: String
  $dob: String
  $isOnBoard: Boolean
  $addressLine1: String!
  $usermeta: [UserMetaCreateWithoutUserInput!]
    ) 
    {
      createCustomerUser(data:{
        firstName:$firstName,
        lastName:$lastName,
        phoneNo:$phoneNo,
        dialCode:$dialCode,
        isOnBoard:$isOnBoard,
        dob:$dob,
        email:$email,
        panCard:$panCard
        gender:$gender
        aadharCard: $aadharCard
        userMeta:{
          create:$usermeta
        }
          userProject:{
            create:{
              Project:{connect:{id:"159dae41-18f7-4e49-912d-0de0274215f8"}}
              Address:{
                create:{
                  addressLine1:$addressLine1,
                  addressType:"user"
                  isprimary:true,
                  isverified:true,
                  title:"title"
                }
              }}}
        }){
        id
        firstName
        lastName
        phoneNo
        dob
        email
        panCard
        gender
        aadharCard
      }
}`;

export const UsersByEmailId = `query UsersByEmailId($emailId: String) {
  Users(where:{email:{equals:$emailId}}){
    data {
      id
			firstName
      phoneNo
      email
      userMeta{
        id
        value
        ProjectMetaKey{
          id
          name
          type
        }
      }
    }
  }
}`;

export const UserByPhone = `query UserByPhone($phoneNo: String) {
  Users(where:{phoneNo:{equals:$phoneNo}}){
    data {
      id
			firstName
      userMeta{
        id
        value
        ProjectMetaKey{
          id
          name
          type
        }
      }
    }
  }
}`;

export const updateCustomerMutation = `mutation updateUser(
  $firstName: String
  $lastName: String
  $email: String
  $phoneNo: String
  $dialCode: String
  $panCard: String
  $gender: genderType
  $aadharCard: String
  $dob: String
  $isOnBoard: Boolean
  $id: String!
  $addressLine1: String
  $userprojectid: String
  $addressid: String
  $usermeta: [UserMetaUpdateWithWhereUniqueWithoutUserInput!]
    ) {
  updateUser(
  args:{id:$id,
    data:{
      firstName:{set:$firstName},
      lastName:{set:$lastName},
      dob:{set:$dob},
      dialCode:{set:$dialCode}
      isOnBoard:{set:$isOnBoard}
      email:{set:$email},
      phoneNo:{set:$phoneNo},
      panCard:{set:$panCard},
      gender:{set:$gender}
      aadharCard:{set:$aadharCard}
      userMeta:{
        update:$usermeta
      }
      userProject:{
        update:{
          where:{id:$userprojectid},
          data:{
            Address:{
              update:{
                where:{id:$addressid},
                data:{addressLine1:{set: $addressLine1}
              }
            }
          }
        }
      }
    }
    }
  }){
  firstName
  id
  panCard
  gender
  aadharCard
}
}`;

export const updateUserMutation = `mutation updateMe(
  $firstName: String
  $lastName: String
  $email: String
  $phoneNo: String
  $dialCode: String
  $panCard: String
  $gender: gendertype
  $aadharCard: String
  $pincode: String!
  $dob: String
  $isOnBoard: Boolean
  $addressLine1: String
  $userprojectid: String
  $addressid: String
  $usermetaid: String
    ) {
      updateMe (args:{
      firstName:{set:$firstName},
      lastName:{set:$lastName},
      dob:{set:$dob},
      dialCode:{set:$dialCode}
      isOnBoard:{set:$isOnBoard}
      email:{set:$email},
      phoneNo:{set:$phoneNo},
      panCard:{set:$panCard},
      gender:{set:$gender}
      aadharCard:{set:$aadharCard}
      userMeta:{
        update:{
          where:{id:$usermetaid},
          data:{
            value:{
              set:$pincode
            },
            ProjectMetaKey:{
              connect:{
                id:"801c8b62-d7ea-4089-b569-804dbfec2f83"
              }
            }
          }
        }
      }
      userProject:{
        update:{
          where:{id:$userprojectid},
          data:{
            Address:{
              update:{
                where:{id:$addressid},
                data:{addressLine1:{set: $addressLine1}
              }
            }
          }
        }
      }
    }
  }){
  firstName
}
}`;

export const updateUserMutationonboard = `mutation updateMe(
  $firstName: String
  $lastName: String
  $email: String
  $phoneNo: String
  $dialCode: String
  $panCard: String
  $gender: genderType
  $aadharCard: String
  $pincode: String!
  $dob: String
  $isOnBoard: Boolean
  $addressLine1: String!
  $userprojectid: String
    ) {
      updateMe(
  args:{
      firstName:{set:$firstName},
      lastName:{set:$lastName},
      dob:{set:$dob},
      dialCode:{set:$dialCode}
      isOnBoard:{set:$isOnBoard}
      email:{set:$email},
      phoneNo:{set:$phoneNo},
      panCard:{set:$panCard},
      gender:{set:$gender}
      aadharCard:{set:$aadharCard}
      userMeta:{
        create:{
            value:$pincode
            ProjectMetaKey:{
              connect:{
                id:"801c8b62-d7ea-4089-b569-804dbfec2f83"
              }
            }
        }
      }
      userProject:{
        update:{
          where:{id:$userprojectid},
          data:{
            Address:{
              create:{
                addressLine1:$addressLine1,
                addressType:"user"
                isprimary:true,
                isverified:true,
                title:"title"
              }
            }
          }
        }
      }
  }){
  firstName
}
}`;

export const cretateaddress = `
mutation CreateCustomerAddress(
  $addressLine1: String!
  $UserProject: String!
) {
  CreateCustomerAddress(
    args: {
      UserProject: {connect: {id: $UserProject}},
      addressLine1: $addressLine1,
      addressType: "user",
      isprimary: true,
      isverified: true,
      title: "title"
          }
  ) {
    addressLine1
    id
  }
}`;

export const updateAddressMutation = `
mutation updateCustomerAddress(
  $addressid: String!
  $addressLine1: String!
) {
  updateCustomerAddress(
    args: {id: $addressid, data: {addressLine1: { set: $addressLine1 }}}
  ) {
    addressLine1
    id
  }
}`;

export const connectWithUserMutation = `mutation createUserCustRelation($new_cust_sk: String, $my_uuid: String ) {
  createUserCustRelation(
    args: {
      isVerified: false, 
      custUser: {connect: {id: $new_cust_sk}}, 
      User: {connect: {id: $my_uuid}}, 
      addedByUser: {connect: {id: $my_uuid}}}
  ) {
    id
    isActive
    userId
  }
}`;

export const getAllCustomers = `query userCustomers {
  userCustomers{
    id
    userId
    updatedAt
    customerId
    addedBy
    custUser{
      id
      firstName
      lastName
      email
      dob
      phoneNo
      dialCode
      panCard
      gender
      aadharCard
      userProject{
        id
        Address{
          id
          addressLine1
        }
      }
      userMeta{
        id
        value
        metaKeyProject
        ProjectMetaKey{
          id
          name
        }
      }
    }
  }
}`;

export const getproductapplication = `query Applications(
  $addedBy:String!
  $pid: String!
   ) {
  Applications(
    where: {addedBy: {equals: $addedBy}, Product: {is: {id: {equals: $pid}}}}
  ) {
    id
    source
    referenceId
    externalId
    customerId
    status
    applicationMessage
    applicationState
    applicationId
    min_loan_amount
    max_loan_amount
    Product {
      id
      name
    }
    ApplicationState {
      label
      name
      id
    }
    Customer {
        id
        lastName
        firstName
        phoneNo
    }
  }
}`;

export const getallapplications = `query Applications($addedBy: String) {
  Applications(where: {addedBy: { equals: $addedBy }}) {
    addedBy
    id
    source
    referenceId
    externalId
    customerId
    status
    applicationMessage
    applicationState
    applicationId
    min_loan_amount
    max_loan_amount
    Product {
      id
      name
    }
    ApplicationState {
      label
      name
      id
    }
    applicationState
    status
    Customer {
        id
        firstName
        lastName
        phoneNo
        email
        dob
      userProject {
        Address {
          addressLine1
        }
      }
    }
  }
}`;

export const getapplication = `query Application($id: String!) {
  Application(id: $id) {
    addedBy
    id
    source
    referenceId
    externalId
    customerId
    status
    createdAt
    updatedAt
    applicationMessage
    applicationState
    applicationId
    ApplicationAsset {
      createdAt
      updatedAt
      status
      id
    }
    Product {
      id
      name
      description
      quantity
      source
      categoryProduct {
        id
        Category {
          id
          name
          parentCategoryId
        }
      }
      productAsset{
        id
        Asset{
          url
        }
      }
    }
    ApplicationState {
      label
      name
      id
      updatedAt
    }
    applicationState
    status
    Customer {
      id
      firstName
      lastName
      phoneNo
      email
      dob
      userProject {
        Address {
          addressLine1
        }
      }
    }
  }
}`;

export const myDetails = `query User($sk: String!){
  User (id:$sk){
    id
    firstName
    lastName
    dob
    email
    phoneNo
    isOnBoard
    panCard
    gender
    aadharCard
    userMeta{
      id
      value
      metaKeyProject
      ProjectMetaKey{
        id
        name
      }
    }
    userGroup{
      id 
      updatedAt
      Group{
        id
        name
        isDefault
        updatedAt
      }
    }
    userProject{
      id
      Address{
        id
        title
        addressLine1
        zipcode
        phoneNumber
        isprimary
        City{
          id
          name
        }
      }
    }
    
  }
}`;
export const createApplicationMutatiion = `mutation createApplication(
  $customerId: String
  $statusId: String!
  $addedBy: String
  $productid: String!
  $sourceId: sourceType
  $tenure: Int
  $min_loan_amount: Int
  $max_loan_amount: Int) {
  createApplication(data:{
    ApplicationState: {
      connect: {
        id:$statusId
      }
    }
    Product:{
      connect: {
        id: $productid
      }
    }
    Customer: {
      connect: {
        id: $customerId
      }
    }
    addedByUser: {
      connect: {
        id: $addedBy
      }
    }
    source:$sourceId
    tenure:$tenure
    min_loan_amount: $min_loan_amount
    max_loan_amount: $max_loan_amount
  }) {
   id
   applicationId
   referenceId
   externalId
  }
}`;

export const kycverifypan = `query kycVerify($card: String){
  kycVerify(data:{
    pan:$card
  }){
    entity
    success
    responseStatus
    data {
      age
      gender
      name
      phone
      state
      status
    }
  }
}`;

export const kycverifyaadhar = `query kycVerify($card: String){
  kycVerify(data:{
    aadhaar:$card
  }){
    entity
    success
    responseStatus
    data {
      age
      gender
      name
      phone
      state
      status
    }
  }
}`;

export const checkEligibilty = `query checkAligibilty($id: String!) {
  checkAligibilty(id: $id) {
    id
  }
}`;
