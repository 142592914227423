
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonicSlides,
  IonSearchbar,
  // IonButton,
  IonItem,
  IonList,
  IonLabel,
  IonAvatar,
  IonSpinner,
} from "@ionic/vue";
import {
  category,
  fetchCategory,
  fetchCategories,
  categories,
} from "@/store/categories";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useStore } from "@nanostores/vue";
import { fetchAllProducts, allProducts } from "@/store/products";
import { setCustomerInState } from "@/store/customers/store";
import { useRoute } from "vue-router";
const storeallproducts = useStore(allProducts);
console.log("storeallproducts", storeallproducts);
const storeCategories = useStore(categories) || [];
const storeCategory = useStore(category) || [];

export default defineComponent({
  name: "CustomersPage",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    // IonButton,
    Swiper,
    SwiperSlide,
    IonSearchbar,
    // IonAvatar,
    // IonLabel,
    // IonItem,
    IonList,
    IonSpinner,
  },
  data() {
    return {
      modules: [IonicSlides, FreeMode],
      setCustomerInState,
      storeallproducts,
      isLoading: false,
      seachtext: "",
      me: localStorage.getItem("me"),
      steps: {
        startDate: "",
        endDate: "",
        value: 0,
      },
      skip: 0,
      take: 10,
      categoryId: null,
      storeCategory,
      storeCategories,
      selectedTab: useRoute()?.params?.id,
      // filteredCategories: ['Loan' , 'Personal Loan' , 'Home Loan' , 'Home Loan' , 'Home Loan']
    };
  },
  async mounted() {
    this.isLoading = true;
    // await fetchAllCustomers();
    await fetchAllProducts({ skip: this.skip, take: this.take });
    // await fetchCategory({ id: useRoute()?.params?.id });
    this.isLoading = false;
    fetchCategories();
  },
  methods: {
    async loadMoreProd() {
      this.isLoading = true;
      // this.skip += 10;
      this.take += 10;
      await fetchAllProducts({
        skip: this.skip,
        take: this.take,
        categoryId: this.categoryId,
      });
      this.isLoading = false;
    },
    async fetchCategoryProducts(_categoryId: any) {
      this.isLoading = true;
      this.skip = 0;
      this.take = 10;
      this.categoryId = _categoryId;
      await fetchAllProducts({
        skip: this.skip,
        take: this.take,
        categoryId: this.categoryId,
      });
      this.isLoading = false;
    },
  },
  computed: {
    filterdata(): any {
      return this.storeallproducts?.filter((x: any) =>
        x?.name.toLowerCase().includes(this.seachtext.toLowerCase())
      );
      // return this.storeallproducts;
    },
    filteredCategories(): any[] {
      const childCategories: any[] = [{ name: "All Products" }];
      this.storeCategories.map((e: any) => {
        childCategories.push(...e.childCategories);
      });
      return childCategories;
    },
  },
});
