import { atom, action } from 'nanostores';

const initialValue: never[] = [];
const allProducts = atom(initialValue);
const allcatProducts = atom(initialValue);
const selectedProduct = atom(null);
const setAllProductsIdInState = action(allProducts, 'setAllProductsIdInState', (store: any, payload: any) => {
	console.log(payload, 'products');
	allProducts.set(payload);
	return store.get();
});

const setAllCatProductsInState = action(allcatProducts, 'setAllCatProductsInState', (store: any, payload: any) => {
	allcatProducts.set(payload);
	return store.get();
});

const setProductInState = action(selectedProduct, 'setProductInState', (store: any, payload: any) => {
	selectedProduct.set(payload);
	return store.get();
});

export { allProducts, setAllProductsIdInState, setProductInState, selectedProduct, setAllCatProductsInState, allcatProducts };
