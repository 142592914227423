<template>
  <ion-page>
    <!-- <ion-fab vertical="bottom" horizontal="center" edge slot="fixed"> -->
    <!-- <ion-fab vertical="bottom" horizontal="center" class="bottom-1.5">
      <ion-fab-button>
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
      <ion-fab-list side="top">
        <ion-fab-button @click="fabCallback"><ion-icon :icon="add"></ion-icon></ion-fab-button>
        <ion-fab-button @click="fabCallback"><ion-icon :icon="documentsOutline"></ion-icon></ion-fab-button>
        <ion-fab-button @click="fabCallback"><ion-icon :icon="cogOutline"></ion-icon></ion-fab-button>
      </ion-fab-list>
    </ion-fab> -->
    <div
      v-if="isLoading"
      class="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-tl to-pink-100 from-green-100 font-medium"
    >
      <ion-spinner class="w-12 h-12 mx-auto my-2" name="crescent"></ion-spinner>
      Fetching user details...
    </div>

    <ion-tabs v-else>
      <ion-router-outlet></ion-router-outlet>
      <!-- <ion-tab-bar v-if="isExistingUser" slot="bottom" class="py-2"> -->
      <ion-tab-bar slot="bottom" class="py-2">
        <ion-tab-button tab="tab1" href="/dashboard/summary">
          <ion-icon :icon="homeOutline" />
          <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/dashboard/customers">
          <ion-icon :icon="peopleOutline" />
          <ion-label>Customers</ion-label>
        </ion-tab-button>

        <!-- <ion-icon :icon="addCircle" class="text-6xl text-black" /> -->
        <!-- fab placed to the bottom and start and on the bottom edge of the content overlapping footer with a list to the right -->

        <!-- <ion-fab-button><ion-icon :icon="addCircle" class="text-6xl text-black" /></ion-fab-button> -->

        <ion-tab-button tab="tab3" href="/dashboard/applications">
          <ion-icon :icon="documentsOutline" />
          <ion-label>Applications</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/dashboard/products">
          <Icon icon="ph:piggy-bank" width="40" />
          <span class="font-medium">Products</span>
        </ion-tab-button>

        <!-- <ion-tab-button tab="settings" href="/dashboard/settings">
          <ion-icon :icon="cogOutline" />
          <ion-label>Profile</ion-label>
        </ion-tab-button> -->
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonSpinner,
} from "@ionic/vue";
import {
  ellipse,
  square,
  triangle,
  appsOutline,
  barChartOutline,
  accessibilityOutline,
  addCircle,
  home,
  homeOutline,
  peopleOutline,
  documentsOutline,
  documentTextOutline,
  cogOutline,
  add,
} from "ionicons/icons";
import { groups, fetchGroups } from "@/store/customers";
import { me, fetchMe, user } from "@/store/users";
import { useStore } from "@nanostores/vue";
import { Icon } from '@iconify/vue';
const meData = useStore(me);
const userData = useStore(user);

const storeGroups = useStore(groups);
export default defineComponent({
  name: "TabsPage",
  // components: { IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet, IonFab, IonFabButton, IonFabList, IonLabel },
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    Icon,
    IonPage,
    IonRouterOutlet,
    IonLabel,
    IonSpinner,
  },
  data() {
    return {
      meData,
      userData,
      storeGroups,
      isLoading: false,
      ellipse,
      square,
      home,
      triangle,
      peopleOutline,
      documentsOutline,
      documentTextOutline,
      cogOutline,
      homeOutline,
      addCircle,
      appsOutline,
      barChartOutline,
      accessibilityOutline,
      add,
    };
  },
  async mounted() {
    fetchGroups();
    this.isLoading = true;
    await fetchMe();
    this.isLoading = false;
    this.verifyOnboarding();
    // const isExistingUser = await this.callApi(JSON.parse(localStorage.user).phoneNumber);
    // console.log(isExistingUser, "isExistingUser");
    // if (isExistingUser) window.location.href = "/dashboard/summary";
    // else window.location.href = "/onboarding";
  },
  computed: {
    isExistingUser() {
      console.log("this.meData", this.meData.isOnBoard);
      return this.meData?.isOnBoard;
    },
  },
  methods: {
    verifyOnboarding() {
      console.log("this.isExistingUser",this.isExistingUser);
      if (this.isExistingUser) {
        console.log("hi");
        // this.$router.push({ path: "/dashboard/summary" });
      } else {
        console.log("bi");
        this.$router.push({ path: "/dashboard/onboarding" });
      }
    },
  },
});
</script>

<style>
@import "swiper/css";
@import "@ionic/vue/css/ionic-swiper.css";
@import "swiper/css/free-mode";

.carousel-img {
  width: 100% !important;
  height: 240px !important;
  object-fit: cover;
}

.news-card-img {
  height: 70px;
  width: 70px;
  min-width: 70px;
}

.news-cards-cont {
  min-width: 300px;
}

.swiper .swiper-slide {
  flex-shrink: 100;
}

.stat-cards-cont {
  min-width: 150px;
}

.custom-underline {
  position: relative;
}

.custom-underline::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 2px;
  background: black;
  bottom: -7px;
  left: 0;
  background-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
</style>
