<template>
  <div
    v-if="loading"
    class="animate-spin border-2 h-5 rounded-full spinner w-5 border-white mr-2"
    style="border-top-color: #78797a"
  />
</template>

<script>
export default {
  name: "BtnSpinner",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style>
</style>