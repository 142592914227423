import { atom, action } from 'nanostores';
// import { fetchCountries } from './actions';

const initialValue: any[] = [];

const countries = atom(initialValue);

const setCountriesInState = action(countries, 'setCountriesInState', (store: any, payload: any) => {
	countries.set([...countries.get(), payload]);
	return store.get();
});

// onMount(countries, () => {
//     task(async () => {
//         console.log('====================')
//         countries.set(await fetchCountries())
//     })
// })

export { countries, setCountriesInState };