// import adCustomer from '@/graphql/addCustomer.gql';
// import checkAligibilty from "@/graphql/checkEligibility.gql";

import {
  addUserMutation,
  getAllCustomers,
  getGroups,
  cretateaddress,
  updateGroup,
  checkEligibilty,
  getproductapplication,
  getallapplications,
  getapplication,
  connectWithUserMutation,
  createApplicationMutatiion,
  myDetails ,
  updateUserMutation, 
  kycverifypan,
  kycverifyaadhar,
  updateAddressMutation
} from "../queries";

import { executeApi } from "../common";
import { setAllCustomersInState, setGroupsInState, setProductCustomerstInState, setAllApplicationsInState, setApplicationInState, setaadharKycInState,setpanKycInState } from "./store";
import { fetchMe } from "../users";

const addNewCustomer = async function addNewCustomer(
  variable: any,
) {
  const response = await executeApi(addUserMutation, variable);
  console.log("response", response);
  // const userid = response.data.createCoreUser.id;
  // const user = await executeApi(myDetails, {sk:userid});
  // console.log("user", user);
  // const newvar = {
  //   ...variable2,
  //   UserProject: user.data.User.userProject[0].id,
  // }
  // console.log("newvar", newvar);
  // const response2 = await executeApi(cretateaddress, newvar);
  // const csm = JSON.parse(variable.csm);
  // await connectWithUser({
  //   new_cust_sk: response.data.createCoreUser.id,
  //   my_uuid: csm.id,
  // });
  fetchAllCustomers();
  return { ...response.data };
};


const connectWithUser = async function connectWithUser(variable: any) {
  const response = await executeApi(connectWithUserMutation, variable);
  console.log(response, "res");
  return response.data;
};

const fetchAllCustomers = async function fetchAllCustomers() {
  try {
    const { data: response } = await executeApi(getAllCustomers);
    setAllCustomersInState(response.userCustomers);
    return response.userCustomers;
  } catch (error) {
    setAllCustomersInState([]);
  }
};

const fetchProductApplication = async function fetchProductApplication(variable: any) {
  const { data: response } = await executeApi(getproductapplication, variable);
  setProductCustomerstInState(response.Applications);
  return response.Applications;
};

const customerpanKyc = async function customerKyc(variable: any) {
  try {
    const { data: response } = await executeApi(kycverifypan, variable);
    setpanKycInState(response.data);
    return response.data;
  } catch (error) {
    setpanKycInState('error');
  }
};

const customeraadharKyc = async function customerKyc(variable: any) {
  try {
    const { data: response } = await executeApi(kycverifyaadhar, variable);
    setaadharKycInState(response.data);
    return response.data;
  } catch (error) {
    setaadharKycInState('error');
  }
};

const fetchAllApplications = async function fetchAllApplications(variable: any) {
  const { data: response } = await executeApi(getallapplications, variable);
  setAllApplicationsInState(response.Applications);
  return response.Applications;
};

const fetchApplication = async function fetchApplication(variable: any) {
  const { data: response } = await executeApi(getapplication, variable);
  setApplicationInState(response.Application);
  return response.Application;
};

const fetchGroups = async function fetchGroups() {
  const { data: response } = await executeApi(getGroups);
  //   console.log(response, "gg");
  setGroupsInState(response.Groups);
  return response.Groups;
};
const updateMyGroup = async function updateMyGroup(variable: any) {
  const { data: response } = await executeApi(updateGroup, variable);
  fetchMe();
  return response;
};

const createApplication = async function createApplication(variable: any) {
  try {
    const { data: response } = await executeApi(
      createApplicationMutatiion,
      variable
    );
    // console.log("response.createApplication?.id1", response.createApplication?.id)
    // console.log("variable.sourceId1", variable.sourceId)

    // if (response.createApplication?.id && response.createApplication?.externalId !== "none" && variable.sourceId !== "system"){ 
    //   console.log("response.createApplication?.id", response.createApplication?.id)
    //   console.log("variable.sourceId", variable.sourceId)
    //   checkEligibility({id: response.createApplication?.id})
    // }
    return response.createApplication;
  } catch (error) {
    console.log("error", error);
  }
};
const updateCustomer = async function updateCustomer(variable: any, variable2: any) {
  const response = await executeApi(updateUserMutation, variable);
  const response2 = await executeApi(updateAddressMutation, variable2);
  fetchMe();
	return {...response.data , ...response2.data };
}


const checkEligibility = async function checkEligibility(variable: any) {
  const { data: response } = await executeApi(checkEligibilty, variable);
  return response;
};
// const updateApplicationStatus = async function updateApplicationStatus(
//   variable: any,
//   variable3: any
// ) {
//   try {
//     // const response = await executeApi(updateUserMutation, variable);
//     // const response2 = await executeApi(updateAddressMutation, variable2);
//     // const csm = JSON.parse(variable.csm);
//     // await connectWithUser({ new_cust_sk: response.data.addCoreUser.sk, my_uuid: csm.sk });
//     await createApplication(variable3);

//     return { ...response.data };
//   } catch (error) {
//     return {};
//   }
// };

export {
  addNewCustomer,
  connectWithUser,
  fetchAllCustomers,
  fetchGroups,
  updateMyGroup,
  createApplication,
  customerpanKyc,
  customeraadharKyc,
  checkEligibility,
  fetchProductApplication,
  updateCustomer ,
  fetchApplication,
  fetchAllApplications ,
  //   updateApplicationStatus,
};
