<template>
  <!-- <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Category page</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ExploreContainer name="Tab 3 page" />
    </ion-content>
  </ion-page>
  -->
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>
          <!-- {{ storeallproduct[0] }} -->
          {{ storeallproduct[0]?.categoryProduct[0]?.Category?.name }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <!-- <div class="flex items-center p-5 pb-0 px-3">
        <ion-searchbar :debounce="500" enterkeyhint="search" inputmode="search" type="search" placeholder="Filter grains..." />
      </div>

      <div class="flex items-center p-5 px-3">
        <ion-searchbar :debounce="500" enterkeyhint="search" inputmode="search" type="search" placeholder="Search anything..." />
      </div> -->
      <!-- <swiper
        :modules="modules"
        slides-per-view="auto"
        :free-mode="true"
        :space-between="12"
        :autoplay="true"
        :keyboard="true"
        :pagination="true"
        :scrollbar="true"
        :zoom="true"
      >
        <swiper-slide
          v-for="(subCategory, index) in filteredCategories"
          :key="index"
          :style="{ 'margin-right': '0px' }"
        >
          <button
            class="mx-2 my-4 bg-gray-50 p-4 py-2 border rounded-lg"
            :class="{ 'bg-blue-50': subCategory?.id === selectedTab }"
            @click="
              $router.replace({
                path: `/dashboard/category/${subCategory?.id}`,
              })
            "
          >
            <h2 class="my-0 text-sm font-semibold whitespace-nowrap">
              {{ subCategory?.name }}
            </h2>
          </button>
        </swiper-slide>
      </swiper> -->
      <div class="mt-4 mb-6 mb-6 px-5 space-y-4">
        <div
          v-for="(product, index) in storeallproduct"
          :key="index"
          @click="setProductInState(product)"
          class="news-cards-cont flex text-left bg-gray-50 p-4 py-3 border justify-start rounded-xl"
        >
          <div class="news-card-img mr-3 rounded-lg bg-gray-200 bg-opacity-60">
            <img
              v-if="product?.productAsset?.length"
              :src="product?.productAsset[0]?.Asset?.url"
              class="rounded-lg shadow-md"
            />
          </div>
          <div>
            <router-link :to="`/dashboard/product/${product?.id}`">
              <h2 class="my-0 text-base font-semibold leading-tight mt-1">
                {{ product?.name }}
              </h2>
            </router-link>

            <p
              class="html text-sm multi-truncate mt-2"
              v-html="product?.description"
            />
            <!-- <h2 class="my-0 text-sm leading-tight text-gray-600 mt-1">
              {{ product?.description }}
            </h2> -->
            <h2
              v-if="product?.quantity"
              class="my-0 text-sm leading-tight text-gray-600 mt-1"
            >
              QTY: {{ product?.quantity }}
            </h2>
          </div>
          <!-- <div class="flex flex-col items-end justify-center">
            <h1 class="text-base m-0 font-bold">&#x20B9; 1000/q</h1>
            <h2 class="my-0 text-xs font-bold leading-tight text-green-600">+7.87 (-1.32%)</h2>
          </div> -->
        </div>
        <button
          v-if="storeallproduct.length > 10"
          @click="loadMoreProd"
          class="flex items-center justify-center px-4 text-center mx-auto text-sm leading-5 text-white font-medium bg-black hover:bg-black md:max-w-max py-2 rounded"
        >
          <span class="mr-1">Load more</span>
        </button>
        <div v-if="isLoading" class="flex flex-col w-full py-8">
          <ion-spinner
            class="w-12 h-12 mx-auto my-2"
            name="crescent"
          ></ion-spinner>
        </div>
        <div
          v-else-if="!storeallproduct?.length"
          class="text-center pt-20 font-medium text-lg text-gray-700"
        >
          <img class="pt-32 px-16" src="/assets/empty.png" />
          No product available for this category...
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonicSlides,
  IonSpinner,
} from "@ionic/vue";
import { FreeMode } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
import { useStore } from "@nanostores/vue";
import { useRoute } from "vue-router";
import {
  fetchAllProducts,
  allProducts,
  allcatProducts,
  fetchAllCatProducts,
} from "@/store/products";
// import {
//   category,
//   fetchCategory,
//   fetchCategories,
//   categories,
// } from "@/store/categories";
import { setProductInState } from "@/store/products/store";

// const storeCategories = useStore(categories) || [];
// const storeCategory = useStore(category) || [];
const storeallproduct = useStore(allcatProducts) || [];

export default defineComponent({
  name: "CategoryPage",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonBackButton,
    IonButtons,
    // Swiper,
    // SwiperSlide,
    IonSpinner,
  },
  data() {
    return {
      setProductInState,
      // storeCategory,
      // storeCategories,
      storeallproduct,
      isLoading: false,
      skip: 0,
      take: 10,
      selectedTab: useRoute()?.params?.id,
      modules: [IonicSlides, FreeMode],
      steps: {
        startDate: "",
        endDate: "",
        value: 0,
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    await fetchAllCatProducts({
      skip: this.skip,
      take: this.take,
      categoryId: useRoute()?.params?.id,
    });
    // await fetchCategory({ id: useRoute()?.params?.id });
    this.isLoading = false;
    // fetchCategories();
  },
  computed: {
    // filteredProducts() {
    //   return this.storeCategory.categoryProduct;
    // },
    // filteredCategories() {
    //   const parent = this.storeCategories.find(
    //     (e) => e.id === this.storeCategory.parentCategoryId
    //   );
    //   return parent?.childCategories;
    // },
  },
  methods: {
    async loadMoreProd() {
      this.isLoading = true;
      // this.skip += 10;
      this.take += 10;
      await fetchAllCatProducts({
        skip: this.skip,
        take: this.take,
        categoryId: useRoute()?.params?.id,
      });
      this.isLoading = false;
    },
  },
});
</script>
<style>
.html a {
  color: #60a5fa !important;
}

.multi-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
