import dayjs from 'dayjs';

export const formatMMDYYYY = (date: any) => {
  return dayjs(date).format('MMM DD, YYYY hh:mm a');
};

export const formatDDMMYYYY = (date: any) => {
  return dayjs(date).format('DD-MM-YYYY');
};

export const formatYYYYMMDD = (date: any) => {
  return dayjs(date).format('YYYY-MM-DD');
};