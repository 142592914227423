
import ThirdPartyPasswordless from "supertokens-web-js/recipe/thirdpartypasswordless";
import { defineComponent } from "vue";
// import * as jose from 'jose';

export default defineComponent({
  mounted: async function () {
    try {
      // we try and consume the authorisation code sent by the social login provider.
      // this knows which third party provider has sent the user back because
      // we store that in localstorage when the user clicks on the provider's button
      // on the sign in / up screen
      console.log("auth call back mounted");
      const response = await ThirdPartyPasswordless.thirdPartySignInAndUp({
        options: {
          preAPIHook: async (context) => {
            const url = context.url;

            // // is the fetch config object that contains the header, body etc..
            const requestInit = context.requestInit;
            requestInit.headers = {
              ...requestInit.headers,
              "x-client-id": "gZySd0BUB6uRr1N",
              "x-host-platform": "MOBILE",
            };

            return {
              requestInit,
              url,
            };
            // return context;
          },
        },
      });
      console.log(response);
      if (response.status !== "OK") {
        // this means that the third party provider does not have an email associated
        // with this user. In this case, we disallow the sign in and show a message
        // on the login UI
        return window.location.assign("/login?error=signin");
      }

      // login / signup is successful, and we redirect the user to the home page.
      // Note that session cookies are added automatically and nothing needs to be
      // done here about them.
      window.location.assign("/dashboard");
    } catch (_) {
      // we show a something went wrong error in the auth page.
      window.location.assign("/login?error=signin");
    }
  },
});
