import { executeApi } from "../common";
import { getUserDynomodb, addUserDynomodb, getMeQuery, updateCustomerMutation, updateUserMutation, updateUserMutationonboard, myDetails, updateAddressMutation,UsersByEmailId, UserByPhone } from "../queries";
import { setUserDynomodbInState, setMeInState, setMeDetailsInState, setCheckByPhone, setCheckByEmail } from "./store";

const fetchMe = async function fetchMe() {
  const response = await executeApi(getMeQuery);
  console.log(response, 'red')
  localStorage.setItem("me", JSON.stringify(response.data.me));

  setMeInState(response.data.me);
  await fetchMeDetails({sk: response.data.me.id})
  return response.data.me;
};

const fetchMeDetails = async function fetchMeDetails(variable: any) {
  const {data: response} = await executeApi(myDetails, variable);
  localStorage.setItem("user", JSON.stringify(response.user));

  setMeDetailsInState(response.User);
  return response.User;
};

const CheckByPhone = async function CheckByPhone(variable: any) {
  try {
    const {data: response} = await executeApi(UserByPhone, variable);
    setCheckByPhone(response.Users.data[0]);
    return response.UserByPhone;
  } catch (error) {
    setCheckByPhone("error");
    return error;
  }
};

const CheckByEmail = async function CheckByEmail(variable: any) {
  try {
    const {data: response} = await executeApi(UsersByEmailId, variable);
    setCheckByEmail(response.Users.data[0]);
    return response.UsersByEmailId;
  } catch (error) {
    setCheckByEmail("error");
    return error;
  }
};

const updateMyDetails = async function updateMyDetails(variable: any) {
	const response = await executeApi(updateUserMutation, variable);
	// const response2 = await executeApi(updateAddressMutation, variable2);
	// const csm = JSON.parse(variable.csm);
	// await connectWithUser({ new_cust_sk: response.data.addCoreUser.sk, my_uuid: csm.sk });
	fetchMe();
	return {...response.data};
	// return {...response.data};
};

const updateCustomerDetails = async function updateCustomerDetails(variable: any) {
	const response = await executeApi(updateCustomerMutation, variable);
	// const csm = JSON.parse(variable.csm);
	// await connectWithUser({ new_cust_sk: response.data.addCoreUser.sk, my_uuid: csm.sk });
	fetchMe();
	return {...response.data};
	// return {...response.data};
};

const updateMyDetailsonboard = async function updateMyDetailsonboard(variable: any) {
	const response = await executeApi(updateUserMutationonboard, variable);
	// const response2 = await executeApi(updateAddressMutation, variable2);
	// const csm = JSON.parse(variable.csm);
	// await connectWithUser({ new_cust_sk: response.data.addCoreUser.sk, my_uuid: csm.sk });
	fetchMe();
	return {...response.data};
	// return {...response.data};
};

const fetchUserFromDynomodb = async function fetchUserFromDynomodb(
  variables: any
) {
  if (variables.phoneNo) variables.phoneNo = variables.phoneNo.replace('+91', '');
  const response = await executeApi(getUserDynomodb, variables);
  setUserDynomodbInState(response.data);
  return response.data;
};

const addUser = async function addUser(variables: any) {
  const response = await executeApi(addUserDynomodb, variables?.first);
  const responseData = response?.data?.addUser;
  console.log("responseData => ", responseData);
  // if (responseData?.id) {
  // const response1 = await executeApi(addUserSQL, {
  //   ...variables?.second,
  //   userId: responseData?.id,
  // });
  // if (response1.data?.CreateUsers?.id)
  //   localStorage.setItem(
  //     "loggedInUser",
  //     JSON.stringify({
  //       firstName: responseData?.firstName,
  //       lastName: responseData?.lastName,
  //       email: responseData?.email,
  //       phone: responseData?.phone,
  //       firebaseUid: responseData?.firebaseUid,
  //     })
  //   );
  // return response1.data;
  // }
  return responseData;
  // setUserDynomodbInState(response.data);
};

export { fetchUserFromDynomodb, fetchMe, addUser,updateCustomerDetails, updateMyDetails, updateMyDetailsonboard, fetchMeDetails, CheckByPhone, CheckByEmail };
