
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonSlides,
  IonSlide,
  IonSegment,
  IonSegmentButton,
  IonAvatar,
  IonLabel,
  IonSpinner,
} from "@ionic/vue";
import { cashOutline } from "ionicons/icons";
import { FreeMode } from "swiper";
import { selectedCustomer } from "@/store/customers/store";
import { useStore } from "@nanostores/vue";
import { fetchAllCustomers, allCustomersStore } from "@/store/customers";
import { updateCustomerDetails, userbyemail } from "@/store/users";
import { Field, Form } from "vee-validate";
const storeSelectedCustomer = useStore(selectedCustomer);
console.log("storeSelectedCustomer", storeSelectedCustomer);
const allCustomers = useStore(allCustomersStore);
import { formatMMDYYYY, formatDDMMYYYY } from "../services/date-format";
const storeuserbyemail = useStore(userbyemail);
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';

let swiper: any = null;

const tabs = ["Details"];

export default defineComponent({
  name: "CustomerDetailPage",
  // components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonButtons, IonSlides, IonSlide, IonSegment, IonSegmentButton, Swiper, SwiperSlide, Tabs, Tab, TabPanels, TabPanel },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonBackButton,
    IonButtons,
    IonSegment,
    IonSegmentButton,
    IonAvatar,
    IonLabel,
    IonSpinner,
    Field,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
  },
  setup() {
    return {
      storeuserbyemail,
    };
  },
  data() {
    return {
      storeSelectedCustomer,
      allCustomers,
      formatMMDYYYY,
      formatDDMMYYYY,
      isLoading: false,
      modules: [FreeMode],
      renderTabs: false,
      tabs,
      selectedTab: "Details",
      cashOutline,
      steps: {
        startDate: "",
        endDate: "",
        value: 0,
      },
      slideOpts: {
        initialSlide: 0,
        speed: 400,
      },
      dateofbirth: "",
      userGroup: {},
      userProject: {},
      step: 3,
      // fullName: "",
      userDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        dialCode: "+91",
        pincode: "",
        dob: "",
        isOnBoard: true,
        panCard: "",
        gender: "",
        aadharCard: "",
        id: "",
        usermetaid: "",
        usermeta: [{}],
        addressLine1: "",
        addressid: "",
        userprojectid: "",
      },
      usermetapincodeid: "",
      customerDetails: {
        custCity: "",
      },
      isverified: true,
    };
  },
  async mounted() {
    this.isLoading = true;
    await fetchAllCustomers();
    this.isLoading = false;
    this.renderTabs = true;
    setTimeout(async () => {
      const x: any = this.$refs["ionSwiper"];
      swiper = await x.$el.getSwiper();
    }, 100);
    this.userDetails.id = this.selectedCustomer?.custUser?.id;
    this.userDetails.firstName = this.selectedCustomer?.custUser?.firstName;
    this.userDetails.lastName = this.selectedCustomer?.custUser?.lastName;
    this.userDetails.email = this.selectedCustomer?.custUser?.email;
    this.userDetails.phoneNo = this.selectedCustomer?.custUser?.phoneNo;
    this.userDetails.panCard = this.selectedCustomer?.custUser?.panCard;
    this.userDetails.gender = this.selectedCustomer?.custUser?.gender;
    this.userDetails.aadharCard = this.selectedCustomer?.custUser?.aadharCard;
    this.dateofbirth = this.selectedCustomer?.custUser?.dob
      .split("-")
      .reverse()
      .join("-");
    this.customerDetails.custCity = this.selectedCustomer?.custUser?.custCity;
    this.userDetails.userprojectid =
      this.selectedCustomer?.custUser?.userProject[0]?.id;
    this.userDetails.addressLine1 =
      this.selectedCustomer?.custUser?.userProject[0]?.Address[0]?.addressLine1;
    this.userDetails.addressid =
      this.selectedCustomer?.custUser?.userProject[0]?.Address[0]?.id;
    this.selectedCustomer?.custUser?.userMeta?.forEach((element: any) => {
      if (element?.metaKeyProject === "801c8b62-d7ea-4089-b569-804dbfec2f83") {
        this.userDetails.pincode = element?.value;
        this.usermetapincodeid = element?.id;
        this.userDetails.usermetaid = element?.id;
      }
    });
  },
  methods: {
    async tabChanged({ detail }: any) {
      const { value } = detail;
      const index = tabs.indexOf(value);
      swiper.slideTo(index);
    },
    async ionSlideChange() {
      const x: any = this.$refs["ionSwiper"];
      this.selectedTab = tabs[await x.$el.getActiveIndex()];
    },
    isRequired(value: any) {
      return value ? true : "This field is required";
    },
    async submitHandler() {
      if (this.isverified) {
        this.isverified = !this.isverified;
      } else {
        console.log(this.userDetails, "uu");
        this.userDetails.dob = formatDDMMYYYY(this.dateofbirth);
        console.log("update");
        this.userDetails.usermeta = [
          {
            where: { id: this.usermetapincodeid },
            data: {
              value: {
                set: this.userDetails.pincode,
              },
              ProjectMetaKey: {
                connect: {
                  id: "801c8b62-d7ea-4089-b569-804dbfec2f83",
                },
              },
            },
          },
        ];
        await updateCustomerDetails(this.userDetails);
        this.isverified = true;
        // this.$router.push({ path: "/dashboard/customers" });
      }
    },
  },
  computed: {
    selectedCustomer(): any {
      if (this.storeSelectedCustomer) {
        return this.storeSelectedCustomer;
      } else {
        return this.allCustomers?.find(
          (e: any) => e.id === this.$route.params?.id
        );
      }
    },
  },
});
