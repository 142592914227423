
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonicSlides,
  IonSearchbar,
  IonItem,
  IonList,
} from "@ionic/vue";
import { cashOutline } from "ionicons/icons";
import { FreeMode } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
import { allapplications, fetchAllApplications } from "@/store/customers";
import { useStore } from "@nanostores/vue";
import { Icon } from "@iconify/vue";

const storeallapplications = useStore(allapplications);
console.log("storeallapplications", storeallapplications);
export default defineComponent({
  name: "ApplicationsPage",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    Icon,
    IonContent,
    IonPage,
    // Swiper,
    // SwiperSlide,
    IonSearchbar,
    IonItem,
    IonList,
  },
  setup() {
    return {
      cashOutline,
      storeallapplications,
    };
  },
  data() {
    return {
      modules: [IonicSlides, FreeMode],
      searchtext: "",
      steps: {
        startDate: "",
        endDate: "",
        value: 0,
      },
      isLoading: false,
    };
  },
  async mounted() {
    const usersk = JSON.parse(localStorage.getItem("me") || "{}");
    this.isLoading = true;
    await fetchAllApplications({ addedBy: usersk.id });
    this.isLoading = false;
    // this.user = JSON.parse(localStorage.getItem("me"));
    // document.getElementById('swiperComp').autoplay(true);
  },
  computed: {
    filterdata(): any {
      return this.storeallapplications.filter((x: any) =>
        x.Customer?.firstName
          ?.toLowerCase()
          .includes(this.searchtext.toLowerCase())
      );
    },
  },
  methods: {
    getdiscription(val: any) {
      if (val !== "none") {
        const disc = JSON.parse(val || "{}");
        console.log("disc===>", disc);
        return disc.errorDesc || val || "No Discription Available";
      } else {
        return "No Status Available";
      }
    },
  },
});
