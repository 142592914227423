
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonSlides,
  IonSlide,
  IonSegment,
  IonSegmentButton,
  IonicSlides,
  IonAvatar,
  IonLabel,
  IonSpinner,
} from "@ionic/vue";
import { cashOutline } from "ionicons/icons";
import { FreeMode } from "swiper";
// import { selectedCustomer } from "@/store/customers/store";
import { useStore } from "@nanostores/vue";
// import { fetchAllCustomers, allCustomersStore } from "@/store/customers";
import {
  application,
  fetchApplication,
  checkEligibility,
} from "@/store/customers";
// const storeSelectedCustomer = useStore(selectedCustomer);
const storeapplication = useStore(application);
// const allCustomers = useStore(allCustomersStore);
import { formatMMDYYYY } from "../services/date-format";

// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';

let swiper: any = null;

const tabs = ["Details"];

export default defineComponent({
  name: "CustomerDetailPage",
  // components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonBackButton, IonButtons, IonSlides, IonSlide, IonSegment, IonSegmentButton, Swiper, SwiperSlide, Tabs, Tab, TabPanels, TabPanel },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonBackButton,
    IonButtons,
    // IonSlides,
    // IonSlide,
    // IonSegment,
    // IonSegmentButton,
    IonAvatar,
    // IonLabel,
    IonSpinner,
  },
  data() {
    return {
      // storeSelectedCustomer,
      storeapplication,
      // allCustomers,
      formatMMDYYYY,
      isLoading: false,
      modules: [IonicSlides, FreeMode],
      renderTabs: false,
      tabs,
      selectedTab: "Details",
      cashOutline,
      steps: {
        startDate: "",
        endDate: "",
        value: 0,
      },
      slideOpts: {
        initialSlide: 0,
        speed: 400,
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    // await fetchAllCustomers();
    await fetchApplication({ id: this.$route.params?.id });
    this.isLoading = false;
    this.renderTabs = true;
    setTimeout(async () => {
      const x: any = this.$refs["ionSwiper"];
      swiper = await x.$el.getSwiper();
    }, 100);
  },
  methods: {
    async tabChanged({ detail }: any) {
      const { value } = detail;
      const index = tabs.indexOf(value);
      swiper.slideTo(index);
    },
    async ionSlideChange() {
      const x: any = this.$refs["ionSwiper"];
      this.selectedTab = tabs[await x.$el.getActiveIndex()];
    },
    async checkeligibility(val: any) {
      await checkEligibility({ id: val });
      await fetchApplication({ id: this.$route.params?.id });
    },
    getdiscription(val: any) {
      if (val !== "none") {
        const disc = JSON.parse(val || "{}");
        console.log("disc===>", disc);
        return disc.errorDesc || val || "No Discription Available";
      } else {
        return "No Status Available";
      }
    },
    getstatus(val: any) {
      console.log("getstatus", val);
      if (val === "none" || val === "undefined") {
        // const disc = JSON.parse(val || "{}");
        return false;
      } else {
        return true;
      }
    },
  },
  computed: {
    // selectedCustomer(): any {
    //   if (this.storeSelectedCustomer) {
    //     return this.storeSelectedCustomer;
    //   } else {
    //     return this.allCustomers?.find(
    //       (e: any) => e.id === this.$route.params?.id
    //     );
    //   }
    // },
  },
});
