import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import Tabs from 'vue3-tabs';
import { IonicVue } from '@ionic/vue';
import SuperTokens from 'supertokens-web-js';
import ThirdPartyPasswordless from 'supertokens-web-js/recipe/thirdpartypasswordless';
// import ThirdPartyEmailPassword from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import Session from 'supertokens-web-js/recipe/session';
// import * as jose from 'jose';
// import { Capacitor } from '@capacitor/core';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/* Theme variables */
import './theme/variables.css';
// import { Capacitor } from '@capacitor/core';

// const port = process.env.APP_PORT || 3000;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if(window.cordova) {
  const onDeviceReady = () => {
    console.log('device ready');
    // const accessToken = localStorage.getItem('accessToken');
    // if (accessToken) {
    //   const accessTokenExpiry = parseInt((localStorage.getItem('accessTokenExpiry') || '0'), 10);
    //   const currentTime = Date.now() / 1000;
    //   console.log('accessTokenExpiry: ', accessTokenExpiry);
    //   console.log('diff: ', accessTokenExpiry - currentTime);
    //   if ((accessTokenExpiry - currentTime) < 300) {
    //     alert('refresh token should be called');
    //     console.log('refetch in background');
    //     const options = 'location=no,navigationbuttoncolor=#ffffff,closebuttoncaption=Close,closebuttoncolor=#ffffff,enableViewportScale=yes';
    //     // if (Capacitor.getPlatform() === 'android') {
    //     //   options = 'hideurlbar=yeslocation=yes';
    //     // }
        
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     const inAppBrowserRef = window.cordova.InAppBrowser.open('https://auth.butterpaper.tech/refresh', '_blank', options);
    //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //     // @ts-ignore
    //     window.inAppBrowserRef = inAppBrowserRef;
    //     inAppBrowserRef.addEventListener('exit', () => {
    //       console.log('destroying inAppBrowserRef');
    //       inAppBrowserRef.close();
    //     });

    //     inAppBrowserRef.addEventListener('loaderror', () => {
    //       console.log('destroying inAppBrowserRef');
    //       inAppBrowserRef.close();
    //     });

    //     inAppBrowserRef.addEventListener('loadstop', () => {
    //       console.log('loading finished');
    //       setTimeout(() => {
    //         inAppBrowserRef.executeScript({ code: `
    //           window.attemptSessionRefresh().then(() => postCookieToCordova());
    //         `
    //         }, () => {
    //           console.log('execution to remote successful');
    //         });
    //       }, 500);
    //     });

    //     inAppBrowserRef.addEventListener('message', async (message: any) => {
    //       const { data } = message;
    //       // console.log(data.type, data.name, data.payload.cookie);
    //       if (data && data.type === 'event' && data.name && data.name === 'AuthLoginCallback') {
    //         const frontTokenFromRequestHeader = getCookie('sFrontToken', data.payload.cookie);
    //         if (frontTokenFromRequestHeader) {
    //           const { up } = JSON.parse(decodeURIComponent(escape(atob(frontTokenFromRequestHeader))));
    //           const { jwt } = up;
    //           if (jwt) {
    //             const decodedToken = jose.decodeJwt(jwt);
    //             const { exp: expiryTime } = decodedToken;
    //             // console.log(decodedToken);
    //             // console.log('===============');
    //             // return frontTokenFromRequestHeader && expiryTime! * 1000 > Date.now() && issuer === 'https://auth.butterpaper.tech/api/auth';
    //             // return frontTokenFromRequestHeader && issuer === 'https://auth.butterpaper.tech/api/auth';
    //             localStorage.setItem('accessTokenExpiry', (expiryTime || '').toString());
    //             localStorage.setItem('accessToken', jwt);
    //             console.log('Message from in app website about successful login');
    //           }
    //         }
    //         inAppBrowserRef.close();
    //       }
    //     });

    //   }
    // }
  }
  document.addEventListener('deviceready', onDeviceReady, false);
} else {
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore
  // const frontTokenFromRequestHeader = window.getCookie('st-access-token', document.cookie);
  // if (frontTokenFromRequestHeader) {
  //   const { userData }: any = jose.decodeJwt(frontTokenFromRequestHeader);
  //   const { jwt } = userData;
  //   if (jwt) {
  //     const decodedToken = jose.decodeJwt(jwt);
  //     const { exp: expiryTime } = decodedToken;
  //     // console.log(decodedToken);
  //     // console.log('===============');
  //     // return frontTokenFromRequestHeader && expiryTime! * 1000 > Date.now() && issuer === 'https://auth.butterpaper.tech/api/auth';
  //     // return frontTokenFromRequestHeader && issuer === 'https://auth.butterpaper.tech/api/auth';
  //     localStorage.setItem('accessTokenExpiry', (expiryTime || '').toString());
  //     localStorage.setItem('accessToken', jwt);
  //     console.log('Message from in app website about successful login');
  //   }
  // } else {
  //   console.log('should clear localstorage');
  //   localStorage.clear();
  // }
}

let orgName = '';
if (window.location.host.includes('royalfinserv')) orgName = 'royalfinserv';

SuperTokens.init({
  appInfo: {
    appName: 'Butterpaper SSO',
    apiDomain: `https://sso${!orgName ? '' : `-${orgName}`}${process.env.VUE_APP_BUILD === 'local' || process.env.VUE_APP_BUILD === 'dev' ? '-dev' : process.env.VUE_APP_BUILD === 'prod' ? '' : `-${process.env.VUE_APP_BUILD}`}.butterpaper.tech`,
    apiBasePath: '/api/auth/',
  },
  recipeList: [
    ThirdPartyPasswordless.init(),
    Session.init({
      autoAddCredentials: true,
      tokenTransferMethod: 'header',
    }),
  ],
});

const app = createApp(App)
.use(IonicVue)
.use(router)
.use(Tabs);
  
router.isReady().then(() => {
  app.mount('#app');
});