
import { ref } from "vue";
import { defineComponent } from "vue";
import { me } from "@/store/users";
import { useStore } from "@nanostores/vue";

import { IonLabel, IonItem, IonList } from "@ionic/vue";
export default defineComponent({
  name: "ExploreContainer",
  components: {
    IonLabel,
    IonItem,
    IonList,
  },
  props: {
    name: String,
  },
  setup() {
    const abc = ref(localStorage.getItem("user"));
    const meData = useStore(me);
    return {
      abc,
      meData,
    };
  },
  methods: {
    logOut() {
      localStorage.clear();
      window.location.href = "/login";
    },
  },
});
