<template>
  <ion-content fullscreen class="ion-padding" scroll-y="false">
    <ion-slides
      pager="true"
      id="slides"
      @ionSlideWillChange="whenChangeSlide()"
    >
      <ion-slide id="1">
        <div class="items-center flex h-full">
          <div class="slide">
            <img
              src="https://ucarecdn.com/116f824e-1b70-4f52-a9d9-b4d88bd89a4d/B4.png"
              alt="Image"
            />
            <div class="p-12">
              <h2>Fast & Easy Application Process</h2>
              <p>
                No unnecessary delay in processing the applications of your
                customers.
              </p>
            </div>
          </div>
        </div>
      </ion-slide>
      <ion-slide>
        <div class="items-center flex h-full">
          <div class="slide">
            <img
              src="https://ucarecdn.com/918a9725-d68a-4dd3-bd43-dda9087096b2/B2.png"
              alt="Image"
            />
            <div class="p-12">
              <h2>Dedicated Mentors</h2>
              <p>To give you the best guidance and maximise your commisions.</p>
            </div>
          </div>
        </div>
      </ion-slide>
      <ion-slide>
        <div class="items-center flex h-full">
          <div class="slide">
            <img
              src="https://ucarecdn.com/ceace372-f873-4320-9e0d-dbe01b10bb1f/B3.png"
              alt="Image"
            />
            <div class="p-12">
              <h2>Timely Settlements</h2>
              <p>Quick real time settlements in your wallet.</p>
            </div>
          </div>
        </div>
      </ion-slide>
      <ion-slide>
        <div class="items-center flex h-full">
          <div class="slide">
            <img
              src="https://ucarecdn.com/fc1678f3-749d-4268-b650-8264d04e22df/B1.png"
              alt="Image"
            />
            <div class="p-12">
              <h2>Let's Start Earning</h2>
              <ion-button fill="clear" @click="goToLogin"
                >Continue
                <button
                  type="button"
                  class="ml-2 text-white bg-black focus:ring-0 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <svg
                    aria-hidden="true"
                    class="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span class="sr-only">Icon description</span>
                </button>
              </ion-button>
            </div>
          </div>
        </div>
      </ion-slide>
    </ion-slides>
    <div class="py-4 px-8 flex justify-end absolute top-0 w-full z-10">
      <button
        type="button"
        @click="jumpTo(4)"
        class="text-black focus:ring-0 focus:outline-none font-medium rounded-full text-sm py-2.5 px-4 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Skip
      </button>
    </div>
    <div
      class="p-8 flex fixed bottom-0 left-0 right-0 w-full z-10"
      :class="[activeSlide === 0 ? 'justify-end' : 'justify-between']"
    >
      <button
        v-if="activeSlide !== 0 && activeSlide !== 3"
        type="button"
        @click="previous()"
        style="transform: rotate(180deg)"
        class="text-white bg-black focus:ring-0 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        <svg
          aria-hidden="true"
          class="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="sr-only">Icon description</span>
      </button>
      <button
        v-if="activeSlide !== 3"
        type="button"
        @click="next()"
        class="text-white bg-black focus:ring-0 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        <svg
          aria-hidden="true"
          class="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="sr-only">Icon description</span>
      </button>
    </div>
  </ion-content>
</template>

<script>
import { IonSlides, IonSlide, IonButton, IonContent } from "@ionic/vue";
import { useRouter } from "vue-router";

export default {
  data() {
    return {
      activeSlide: 0,
    };
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  components: {
    IonSlide,
    IonSlides,
    IonButton,
    IonContent,
  },
  beforeCreate() {
    if(localStorage.getItem('accessToken') && localStorage.getItem('accessToken')?.length) window.location.href = "/dashboard/summary";
  },
  methods: {
    whenChangeSlide() {
      document
        .getElementById("slides")
        ?.getActiveIndex()
        .then((resp) => {
          this.activeSlide = resp;
        });
    },
    jumpTo(val) {
      document.getElementById("slides").slideTo(val, 0);
    },
    next() {
      document.getElementById("slides").slideNext();
    },
    previous() {
      document.getElementById("slides").slidePrev();
    },
    goToLogin() {
      // this.router.replace('/login')
      window.location.href = "/onboarding";
    },
  },
};
//
</script>
<style>
ion-slides {
  height: 100%;
}
.swiper-slide {
  display: block;
}
.swiper-pagination {
  position: absolute;
  top: 48px;
  padding-bottom: 3px;
  z-index: 0;
}
</style>
