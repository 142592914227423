export async function executeApi(query: any, variables = {}) {
  const ACCESS_TOKEN = localStorage.getItem('accessToken');

  const DOMAIN = `https://api${process.env.VUE_APP_BUILD === 'local' || process.env.VUE_APP_BUILD === 'dev' ? '-dev' : process.env.VUE_APP_BUILD === 'prod' ? '' : `-${process.env.VUE_APP_BUILD}`}.butterpaper.tech`;
// const DOMAIN = `https://api-dev.butterpaper.tech`;
const GRAPHQL_ENDPOINT = `${DOMAIN}/graphql`;

  return fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${ACCESS_TOKEN}`,
      'x-bp-origin': 'royalfinserv-app-dev.butterpaper.tech'
    },
    body: JSON.stringify({ query, variables }),
  }).then(async res => {
    const json = await res.json();
    if ((!res.ok && (res.status === 401 || res.status === 403)) || json.errors && json.errors.length && json.errors.find((x: any) => x.message && x.message.toLowerCase() === 'unauthenticated')) {
      localStorage.clear();
      window.location.href = "/login";
      return Promise.reject(res);
    }

    try {
      console.log(json);
      if (json.errors && json.errors[0].message && json.errors[0].message.toLowerCase().includes('forbidden')) {
        console.log('===========================: ', res);
        localStorage.clear();
        window.location.href = "/login";
        return Promise.reject(res);
      }
    } catch (error) {
      console.log(error);
    }

    return json;
  });
}
