
import { defineComponent, ref } from 'vue';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import ExploreContainer from '@/components/ExploreContainer.vue';

import { useStore } from '@nanostores/vue';
import { counter, increaseCounter, decreaseCounter } from '../store/counter';
import { countries, fetchCountries } from '../store/countries';

export default defineComponent({
  name: 'Tab3Page',
  components: { ExploreContainer, IonHeader, IonToolbar, IonTitle, IonContent, IonPage },
  setup() {
		const count = ref(0);
		const add = () => (count.value = count.value + 1);
		const subtract = () => (count.value = count.value - 1);
		const fetchUsData = async () => await fetchCountries('US');

		const storeCount = useStore(counter);
		const storeCountries = useStore(countries);

		if (!storeCountries.value.length) fetchCountries('IN');
		return {
			count,
			add,
			subtract,
			storeCount,
			storeCountries,
			increaseCounter,
			decreaseCounter,
			fetchUsData,
		};
	},
});
