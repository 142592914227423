<template>
  <ion-page>
    <!-- <ion-header>
      <ion-toolbar>
        <ion-title>Tab 1</ion-title>
      </ion-toolbar>
    </ion-header> -->
    <ion-content :fullscreen="true">
      <!-- <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large"></ion-title>
        </ion-toolbar>
      </ion-header> -->

      <!-- <ExploreContainer name="Tab 1 page" /> -->
      <router-link
        to="/dashboard/settings"
        class="flex items-center p-5 offset-ios mt-7"
      >
        <div class="mr-4 overflow-hidden border-2 rounded-full border-blue-400">
          <img
            src="@/assets/images/my_thumb.png"
            alt="avatar"
            width="55"
            height="55"
            class="object-cover transform scale-125"
          />
        </div>
        <div>
          <h1 class="mt-0 mb-2 text-2xl font-bold leading-none">
            Hello {{ user?.firstName || "there" }}!
          </h1>
          <h2 class="my-0 text-sm leading-none text-gray-500">
            Let's make some money today!
          </h2>
        </div>
      </router-link>
      <h1 class="my-2 mb-4 ml-6 text-base leading-none">
        Quick account refresher
      </h1>
      <swiper
        id="swiperComp"
        :modules="modules"
        slides-per-view="auto"
        :free-mode="true"
        :space-between="17"
        :autoplay="true"
        :keyboard="true"
        :pagination="true"
        :scrollbar="true"
        :zoom="true"
      >
        <swiper-slide
          @click="$router.push({ path: '/dashboard/customers' })"
          class="pl-5"
        >
          <div
            class="stat-cards-cont text-left bg-gray-50 p-5 border border-black justify-evenly border-opacity-10 rounded-3xl"
          >
            <!-- <ion-icon :icon="cashOutline" class="text-2xl mb-2 text-gray-600" /> -->
            <h1 class="mb-2 mt-0 text-2xl font-bold leading-none text-black">
              {{ getcustomerscount(storeallCustomersStore) }}
            </h1>
            <h2 class="my-0 text-sm leading-tight text-gray-600">
              Total<br />Customers
            </h2>
          </div>
        </swiper-slide>
        <swiper-slide
          @click="$router.push({ path: '/dashboard/applications' })"
          class=""
        >
          <div
            class="stat-cards-cont text-left bg-gray-50 p-5 border border-black justify-evenly border-opacity-10 rounded-3xl"
          >
            <!-- <ion-icon :icon="cashOutline" class="text-2xl mb-2 text-gray-600" /> -->
            <h1 class="mb-2 mt-0 text-2xl font-bold leading-none text-black">
              {{ getleadscount(storeallapplications) }}
            </h1>
            <h2 class="my-0 text-sm leading-tight text-gray-600">
              Total<br />Applications
            </h2>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div
            class="stat-cards-cont text-left bg-gray-50 p-5 border border-black justify-evenly border-opacity-10 rounded-3xl"
          >
            <!-- <ion-icon :icon="cashOutline" class="text-2xl mb-2 text-gray-600" /> -->
            <h1 class="mb-2 mt-0 text-2xl font-bold leading-none text-black">
              {{ getapprovedcount(storeallapplications) }}
            </h1>
            <h2 class="my-0 text-sm leading-tight text-gray-500">
              Approved<br />Applications
            </h2>
          </div>
        </swiper-slide>
        <!-- <swiper-slide>
          <div
            class="stat-cards-cont text-left bg-gray-50 p-5 border border-black justify-evenly border-opacity-10 rounded-3xl"
          >
            <ion-icon :icon="cashOutline" class="text-2xl mb-2 text-gray-600" />
            <h1 class="mb-2 mt-0 text-2xl font-bold leading-none text-black">
              {{ getpendingcount(storeallapplications) }}
            </h1>
            <h2 class="my-0 text-sm leading-tight text-gray-600">
              Pending<br />Applications
            </h2>
          </div>
        </swiper-slide> -->
      </swiper>
      <autoplay-slider :banners="banners" class="pb-4 pt-9" />
      <!-- <div
        class="relative flex h-48 px-5 py-4 m-5 mt-5 rounded-3xl text-white bg-custom-brown-black border border-black border-opacity-10"
      >
        <span class="absolute top-0 left-0 z-0 w-full h-full transform scale-95 translate-y-6 bg-custom-light-green rotate-3 rounded-3xl"></span>
        <span class="absolute top-0 left-0 z-0 w-full h-full bg-custom-brown-black rounded-3xl scale-in"></span>

        <div class="relative flex flex-col justify-between w-full">
          <h1 class="mt-2 mb-0 text-xl font-semibold leading-none">
            Monthly Earnings
          </h1>

          <div class="flex justify-around w-full pb-3">
            <div class="flex flex-col items-center justify-end flex-1 h-full">
              <svg
                width="8"
                height="1"
                viewBox="0 0 8 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 29C6.20914 29 8 27.2091 8 25L8 4C8 1.79086 6.20914 -1.70367e-07 4 -1.96711e-07V-1.96711e-07C1.79086 -2.23055e-07 2.44993e-07 1.79086 2.18649e-07 4L-3.17731e-08 25C-5.81169e-08 27.2091 1.79086 29 4 29V29Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
              <span class="mt-1 text-sm text-gray-400">Apr</span>
            </div>
            <div class="flex flex-col items-center justify-end flex-1 h-full">
              <svg
                width="8"
                height="1"
                viewBox="0 0 8 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 51C6.20914 51 8 49.2091 8 47L8 4C8 1.79086 6.20914 -2.13558e-08 4 -4.76995e-08C1.79086 -7.40432e-08 -3.66861e-07 1.79086 -3.93205e-07 4L-9.05975e-07 47C-9.32319e-07 49.2091 1.79086 51 4 51Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
              <span class="mt-1 text-sm text-gray-400">May</span>
            </div>
            <div class="flex flex-col items-center justify-end flex-1 h-full">
              <svg
                width="8"
                height="1"
                viewBox="0 0 8 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 2C7.55229 2 8 1.55228 8 1C8 0.447715 7.55228 1.1387e-07 7 1.07284e-07L0.999999 3.57351e-08C0.447714 2.91492e-08 -9.35164e-07 0.447715 -9.41749e-07 1C-9.48335e-07 1.55228 0.447714 2 0.999999 2L7 2Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
              <span class="mt-1 text-sm text-gray-400">Jun</span>
            </div>
            <div class="flex flex-col items-center justify-end flex-1 h-full">
              <svg
                width="8"
                height="1"
                viewBox="0 0 8 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 87C6.20914 87 8 85.2091 8 83L8 4C8 1.79086 6.20914 -2.13558e-08 4 -4.76995e-08C1.79086 -7.40433e-08 6.24345e-08 1.79086 3.60908e-08 4L-9.05975e-07 83C-9.32319e-07 85.2091 1.79086 87 4 87Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
              <span class="mt-1 text-sm text-gray-400">Jul</span>
            </div>
            <div class="flex flex-col items-center justify-end flex-1 h-full">
              <svg
                width="8"
                height="1"
                viewBox="0 0 8 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 60C6.20914 60 8 58.2091 8 56L8 4C8 1.79086 6.20914 3.79334e-06 4 3.767e-06C1.79086 3.74065e-06 -2.59537e-07 1.79086 -2.85881e-07 4L-9.05975e-07 56C-9.32319e-07 58.2091 1.79086 60 4 60Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
              <span class="mt-1 text-sm text-gray-400">Aug</span>
            </div>
            <div class="flex flex-col items-center justify-end flex-1 h-full">
              <svg
                width="8"
                height="1"
                viewBox="0 0 8 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 87C6.20914 87 8 85.2091 8 83L8 4C8 1.79086 6.20914 -2.13558e-08 4 -4.76995e-08C1.79086 -7.40433e-08 6.24345e-08 1.79086 3.60908e-08 4L-9.05975e-07 83C-9.32319e-07 85.2091 1.79086 87 4 87Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
              <span class="mt-1 text-sm text-gray-400">Sep</span>
            </div>
            <div class="flex flex-col items-center justify-end flex-1 h-full">
              <svg
                width="8"
                height="1"
                viewBox="0 0 8 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 51C6.20914 51 8 49.2091 8 47L8 4C8 1.79086 6.20914 -2.13558e-08 4 -4.76995e-08C1.79086 -7.40432e-08 -3.66861e-07 1.79086 -3.93205e-07 4L-9.05975e-07 47C-9.32319e-07 49.2091 1.79086 51 4 51Z"
                  fill="white"
                />
              </svg>
              <span class="mt-1 text-sm">Oct</span>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <swiper :modules="modules" slides-per-view="auto" :free-mode="true" :space-between="20" :autoplay="true" :keyboard="true" :pagination="true" :scrollbar="true" :zoom="true">
        <swiper-slide>
          <img
            class="carousel-img"
            src="https://naive-ui.oss-cn-beijing.aliyuncs.com/carousel-img/carousel1.jpeg"
          >
        </swiper-slide>
        <swiper-slide>
          <img
            class="carousel-img"
            src="https://naive-ui.oss-cn-beijing.aliyuncs.com/carousel-img/carousel2.jpeg"
          >
        </swiper-slide>
        <swiper-slide>
          <img
            class="carousel-img"
            src="https://naive-ui.oss-cn-beijing.aliyuncs.com/carousel-img/carousel3.jpeg"
          >
        </swiper-slide>
        <swiper-slide>
          <img
            class="carousel-img"
            src="https://naive-ui.oss-cn-beijing.aliyuncs.com/carousel-img/carousel4.jpeg"
          >
        </swiper-slide>
      </swiper> -->
      <div v-if="!isLoading">
        <div v-for="(category, index) in storeCategories" :key="index">
          <div v-if="!category?.parentCategoryId" class="">
            <h1 class="mt-10 mb-6 ml-6 text-lg leading-none">
              <span class="font-semibold custom-underline inline-block">
                {{ category?.name }}</span
              >&nbsp;by categories
              <!-- &nbsp;<span class="text-sm text-yellow-500">Earn upto 5980</span> -->
            </h1>
            <!-- <h1
              v-if="
                !storeCategories.filter(
                  (obj) => obj?.parentCategoryId === category?.id
                )?.length
              "
              class="p-4 text-lg text-center leading-none"
            >
              No category available
            </h1> -->
            <div class="grid grid-cols-3 gap-4 mb-6 place-content-stretch px-5">
              <div
                class="flex flex-col"
                v-for="(subCategory, index) in category.childCategories"
                :key="index"
              >
                <router-link
                  :to="`/dashboard/category/${subCategory?.id}`"
                  class="overflow-hidden px-2 bg-white text-black flex flex-col items-center justify-center rounded-2xl"
                >
                  <img
                    :src="
                      (subCategory.categoryAsset.length &&
                        subCategory.categoryAsset[0].asset.url) ||
                      'https://ucarecdn.com/108b9469-15c3-4841-af24-99c617d7b11d/royal_finservLOGO1.png'
                    "
                    class="rounded-lg shadow-md"
                    :alt="subCategory?.name"
                  />
                  <p class="mb-2 mt-2 text-sm text-center">
                    {{ subCategory.name }}
                  </p>
                </router-link>
              </div>
              <!-- <router-link
                to="/dashboard/category/2"
                class="overflow-hidden px-2 bg-white text-black border flex flex-col items-center justify-center rounded-2xl"
              >
                <img
                  src="https://www.getillustrations.com/packs/splattered-brush-empty-state-vector-illustrations/scenes/_1x/finance%20_%20fishing,%20money,%20financial,%20investment,%20man,%20dollar_md.png"
                  alt=""
                />
                <p class="mb-2 -mt-1 text-sm">Auto</p>
              </router-link>
              <router-link
                to="/dashboard/category/3"
                class="overflow-hidden px-2 bg-white text-black border flex flex-col items-center justify-center rounded-2xl"
              >
                <img
                  src="https://www.getillustrations.com/packs/humanic-character-illustrations-and-scenes-vector-pack/scenes/_1x/finance%20_%20increase%20money,%20finances,%20arrow,%20woman_md.png"
                  alt=""
                />
                <p class="mb-2 -mt-1 text-sm">Personal</p>
              </router-link>
              <router-link
                to="/dashboard/category/4"
                class="overflow-hidden px-2 bg-white text-black border flex flex-col items-center justify-center rounded-2xl"
              >
                <img
                  src="https://www.getillustrations.com/packs/abstrike-empty-state-illustrations/scenes/_1x/Finance%20_%20Money,%20Cash,%20Profit,%20Salary,%20Credit,%20Bag,%20Fortunate,%20Fortune_md.png"
                  alt=""
                />
                <p class="mb-2 -mt-1 text-sm">Business</p>
              </router-link>
              <router-link
                to="/dashboard/category/5"
                class="overflow-hidden px-2 bg-white text-black border flex flex-col items-center justify-center rounded-2xl"
              >
                <img
                  src="https://www.getillustrations.com/packs/playset-flat-illustrations-pack/scenes/_1x/education,%20web%20development%20_%20IT,%20coding,%20programming,%20code,%20computer_md.png"
                  alt=""
                />
                <p class="mb-2 -mt-1 text-sm">Property</p>
              </router-link> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col w-full py-8">
        <ion-spinner
          class="w-12 h-12 mx-auto my-2"
          name="crescent"
        ></ion-spinner>
        <!-- <div class="text-lg text-center mx-auto">Loading...</div> -->
      </div>
      <autoplay-slider
        class="pb-6"
        :banners="bannersBtm"
        heading="<span
        class='font-semibold custom-underline inline-block'>Featured</span> &nbsp;Videos"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "@nanostores/vue";
import {
  IonPage,
  // IonHeader,
  // IonToolbar,
  // IonTitle,
  IonContent,
  IonicSlides,
  // IonIcon,
  IonSpinner,
} from "@ionic/vue";
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { categories, fetchCategories } from "@/store/categories";
import { allCustomersStore, fetchAllCustomers } from "@/store/customers";
import AutoplaySlider from "@/components/AutoplaySlider.vue";
import { me } from "@/store/users";
import { allapplications, fetchAllApplications } from "@/store/customers";
// import { filter } from "ionicons/icons";

const storeCategories = useStore(categories);
const storeallapplications = useStore(allapplications);
const storeallCustomersStore = useStore(allCustomersStore);
const user = useStore(me);

export default defineComponent({
  name: "HomePage",
  components: {
    // IonHeader,
    // IonToolbar,
    // IonTitle,
    IonContent,
    IonPage,
    Swiper,
    SwiperSlide,
    // IonIcon,
    IonSpinner,
    AutoplaySlider,
  },
  data() {
    return {
      user,
      storeCategories,
      storeallapplications,
      storeallCustomersStore,
      modules: [IonicSlides, FreeMode],
      isLoading: false,
      steps: {
        startDate: "",
        endDate: "",
        value: 0,
      },
      banners: [
        {
          imgUrl:
            "https://ucarecdn.com/8b55ddaa-8646-45c2-ab3b-436cac2bd8e9/1.png",
          name: "Slide1",
          order: 1,
          goToUrl: "/dashboard/category/83ad2821-8b0a-4fca-a6a1-671499ddfb98",
          class: "ml-5",
        },
        {
          imgUrl:
            "https://ucarecdn.com/8d7683cf-c92c-41bc-a3b0-c7c1b05738e2/2.png",
          name: "Slide2",
          order: 2,
          goToUrl: "/dashboard/category/602db8ab-d9ab-430b-8845-9208f2b48cbf",
          class: "",
        },
        {
          imgUrl:
            "https://ucarecdn.com/8bba8537-84f2-4f31-b7fb-2fe8e8fdef12/3.png",
          name: "Slide3",
          order: 3,
          goToUrl: "/dashboard/category/f5796813-4ff7-4044-818c-15ec42d9e3a7",
          class: "mr-5",
        },
      ],
      bannersBtm: [
        {
          imgUrl:
            "https://ucarecdn.com/79060eec-fd7b-4660-a48a-98ba8df8805f/4.png",
          name: "Slide4",
          order: 1,
          class: "ml-5 border shadow-lg rounded-lg",
        },
        {
          imgUrl:
            "https://ucarecdn.com/2ad1e645-7907-4c91-a9dd-8303df9a763a/5.png",
          name: "Slide5",
          order: 2,
          class: "border shadow-lg rounded-lg",
        },
        {
          imgUrl:
            "https://ucarecdn.com/61a796cb-4542-4c95-8010-31d0dd52f5b2/6.png",
          name: "Slide6",
          order: 3,
          class: "mr-5 border shadow-lg rounded-lg",
        },
      ],
    };
  },
  async mounted() {
    await fetchAllCustomers();
    const usersk = JSON.parse(localStorage.getItem("me") || "{}");
    await fetchAllApplications({ addedBy: usersk.id });
    this.isLoading = true;
    await fetchCategories();
    this.isLoading = false;
    // this.user = JSON.parse(localStorage.getItem("me"));
    // document.getElementById('swiperComp').autoplay(true);
  },
  computed: {},
  methods: {
    getpendingcount(val) {
      const abc = val.filter(
        (x) => x.ApplicationState?.id === "7fd175ee-1cbb-42b7-9566-fc6d0679b609"
      );
      return abc.length;
    },
    getapprovedcount(val) {
      const abc = val.filter(
        (x) => x.ApplicationState?.id === "7fa9f4fe-a24f-494a-860e-c859c07d9a6e"
      );
      return abc.length;
    },
    getleadscount(val) {
      return val.length;
    },
    getcustomerscount(val) {
      return val.length;
    },
  },
});
</script>

<style>
.offset-ios {
  padding-top: var(–ion-safe-area-top, 0) !important;
}

.offset-ios {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
  --ion-safe-area-top: 20px !important;
  --ion-safe-area-bottom: 20px !important;
}

.offset-ios {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}
</style>
